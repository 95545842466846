import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";

interface IPropCustomSwitch {
    dataTestId: string;
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void);
    checked?: boolean;
    valueSwitch: boolean;
    name: string;
}

const CustomSwitch = ({
    dataTestId,
    onChange,
    checked,
    valueSwitch,
    name,
}: IPropCustomSwitch) => {
    return <IOSSwitch
        data-test-id={dataTestId}
        name={name}
        onChange={onChange}
        checked={valueSwitch}
        isLightTheme={checked}
    />
}

const IOSSwitch = withStyles(({
    switchBase: {
        padding: 0,
        transform: "translate(3px,4px)",
        "&$checked": {
            "& + $track": {
                background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                opacity: 1,
                border: "none",
            },
            transform: "translate(22px,4px)",
        }
    },
    thumb: {
        width: 24,
        height: 24,
        margin: "-2px -1px",
        backgroundColor: "#FFFFFF"
    },

    root: {
        padding: 0,
        height: 28,
        width: 48,
    },

    track: {
        borderRadius: 26 / 2,
        opacity: 1,
        backgroundColor: (props: { isLightTheme?: boolean }) => (props.isLightTheme ? "#6B6B6B" : "#4E4E4E"),
        // transition:theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(Switch);

export default CustomSwitch;