// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import { toast } from "react-toastify";
import uuid from "uuid";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  checked: boolean;
}

interface S {
  loggedUserId: string | null;
  isLoading: boolean;
  livestreamTitle: string;
  selectedAudience: string;
}

interface SS {
  id: any;
}

export default class StartLivestreamController extends BlockComponent<
  Props,
  S,
  SS
> {
  createLivestreamStoryId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loggedUserId: null,
      isLoading: false,
      livestreamTitle: "",
      selectedAudience: this.audience[0],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  audience = ["Everyone", "Followers", "Paid Subscriber"];

  async componentDidMount() {
    const loggedUserId = this.props.navigation.getParam("loggedUserId");
    this.setState({ loggedUserId });
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.createLivestreamStoryId) {
        if (responseJson?.data) {
          this.setState({ isLoading: false });
          const channelName =
            responseJson.data.attributes?.livestream_session?.channel_name;
          const chatRoomId =
            responseJson.data.attributes?.livestream_session?.chatroom_id;
          if (channelName) {
            this.props.navigation.navigate("LiveStreaming", {
              channelName,
              chatRoomId,
              role: "host",
              ownerId: this.state.loggedUserId,
              storyId: responseJson.data.id,
            });
          }
        } else {
          toast.error("Start livestream fail");
          this.setState({ isLoading: false });
        }
      }
    }
  }

  createLivestreamStory = async () => {
    const token = await getStorageData("authToken");
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append("body", this.state.livestreamTitle);
    formData.append("target_audience", "everyone");
    formData.append(
      "livestream_session_attributes[channel_name]",
      uuid.v4().toString()
    );
    const url = "bx_block_broadcast_message/stories";
    this.createLivestreamStoryId = sendAPIRequest(url, {
      method: "POST",
      headers: {
        token,
      },
      body: formData,
    });
  };

  handleAudience = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedAudience: event.target.value as string });
  };

  handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ livestreamTitle: event.target.value });
  };
}

// Customizable Area End
