import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import AC, { AgoraChat } from "agora-chat";
import { makeStyles } from "@material-ui/core/styles";
import { sendIcon } from "../../blocks/chat/src/assets";
import { IUserProfile } from "../../blocks/customisableuserprofiles/src/ProfileController.web";

export interface ChatInLivestreamRef {
  logoutChat: () => void;
}

interface ChatInLivestreamProps {
  roomId: string;
  userId: string;
  chatToken: string;
  loggedUser: IUserProfile;
}

const ChatInLivestream = forwardRef<ChatInLivestreamRef, ChatInLivestreamProps>(
  ({ roomId, userId, chatToken, loggedUser }, ref) => {
    const appKey: string = "611271509#1465827";
    const classes = useStyles();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [messageList, setMessageList] = useState<AgoraChat.TextMsgBody[]>([]);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const messagesContainerRef = useRef<HTMLDivElement | null>(null);

    const chatClient = useRef<AgoraChat.Connection | null>(null);

    // Logs into Agora Chat.
    const handleLogin = () => {
      chatClient.current?.open({
        user: userId,
        accessToken: chatToken,
      });
    };

    const handleJoinChatRoom = () => {
      chatClient.current
        ?.joinChatRoom({ roomId: roomId })
        .then(() => {
          getHistoryMessages();
        })
        .catch((error) =>
          console.log("Join chat room fail with error: ", error)
        );
    };

    const getHistoryMessages = () => {
      chatClient.current
        ?.getHistoryMessages({
          targetId: roomId,
          chatType: "chatRoom",
          pageSize: 100,
          searchDirection: "up",
        })
        .then((res) => {
          const messages = res.messages.reverse() as AgoraChat.TextMsgBody[];
          setMessageList([...messages]);
          if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop =
              messagesContainerRef.current.scrollHeight;
          }
        })
        .catch((error) =>
          console.log("Get chat room history fail with error: ", error)
        );
    };

    // Logs out.
    const handleLogout = () => {
      chatClient.current?.leaveChatRoom({ roomId: roomId })
      .catch((error) =>
        console.log("Leave chat room fail with error: ", error)
      );
      chatClient.current?.close();
      setIsLoggedIn(false);
    };

    useImperativeHandle(ref, () => ({
      logoutChat: handleLogout,
    }));

    const sendTextMessage = () => {
      if (message.trim()) {
        const msg = AC.message.create({
          type: "txt",
          msg: message,
          to: roomId,
          chatType: "chatRoom",
          ext: loggedUser.attributes,
          priority: "normal",
        });
        chatClient.current
          ?.send(msg)
          .then((res) => {
            setMessage("");
            const msgRes = res.message as AgoraChat.TextMsgBody;
            setMessageList([...messageList, msgRes]);
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
          })
          .catch((e) => {
            console.log("Send message fail with error: ", e);
          });
      } else {
        console.log("Please enter message content");
      }
    };

    useEffect(() => {
      chatClient.current = new AC.connection({
        appKey: appKey,
      });

      handleLogin();

      // Adds the event handler.
      chatClient.current.addEventHandler("connection&message", {
        // Occurs when the app is connected to Agora Chat.
        onConnected: () => {
          setIsLoggedIn(true);
          console.log(`User ${userId} Connect success!`);
          handleJoinChatRoom();
        },
        // Occurs when the app is disconnected from Agora Chat.
        onDisconnected: () => {
          setIsLoggedIn(false);
          console.log(`User Logout!`);
        },
        // Occurs when a text message is received.
        onTextMessage: (message: AgoraChat.TextMsgBody) => {
          setMessageList((prev) => [...prev, message]);
          messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        },
        // Occurs when the token is about to expire.
        onTokenWillExpire: () => {
          console.log("Token is about to expire");
        },
        // Occurs when the token has expired.
        onTokenExpired: () => {
          console.log("Token has expired");
        },
        onError: (error: { message: string }) => {
          console.log(`on error: ${error.message}`);
        },
      });

      // Cleanup on unmount
      return () => {
        chatClient.current?.close();
      };
    }, []);

    return (
      <div className={classes.container}>
        <div
          ref={messagesContainerRef}
          className={classes.messageListContainer}
        >
          {messageList.map((message) => (
            <div key={message.id} className={classes.messageWrapper}>
              <img
                className={classes.avatar}
                src={
                  message.ext?.profile_photo ||
                  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
              <div>
                <p className={classes.userName}>
                  {message.ext?.full_name || " Tynisha Obey"}
                </p>
                <p className={classes.messageText}>{message.msg}</p>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Send message"
            className={classes.messageInput}
          />
          <button onClick={sendTextMessage} className={classes.sendMsgBtn}>
            <img src={sendIcon} alt="" />
          </button>
        </div>
      </div>
    );
  }
);

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "24px 0",
  },
  messageListContainer: {
    flex: 1,
    overflowY: "auto",
    padding: "0 16px",
    textAlign: "left",
  },
  messageWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginTop: "12px",
  },
  avatar: {
    width: "34px",
    height: "34px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  userName: {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 700,
    lineHeight: 1.4,
    color: "#fff",
  },
  messageText: {
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#fff",
  },
  inputContainer: {
    display: "flex",
    padding: "0 12px",
    marginTop: "12px",
    gap: "12px",
  },
  messageInput: {
    flex: 1,
    padding: "15px 20px",
    borderRadius: "10px",
    border: "1px solid #6B6B6B",
    background: "transparent",
    color: "#fff",
  },
  sendMsgBtn: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: "56px",
    minWidth: "56px",
    borderRadius: "10px",
    padding: "10px",
    border: "none",
  },
}));

export default ChatInLivestream;
