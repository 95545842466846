import React from 'react'
// Customizable Area Start 
import {
    Box,
    Dialog,
    styled,
    Button,
    Tabs,
    Grid,
    Tab,
    TextField,
    Typography,
    CircularProgress,
    IconButton
} from '@material-ui/core'
import {darkArrowIcon,ArrowIcon, lightCross, crossIcon, ckeckedLogo, cover, sharkTank, ImageIcon} from "./assets"
import CreateHighlightStoriesController,{IStoryAttributes, Props} from './CrerateHighlightStoriesController.web';
import ReactPlayer from 'react-player';
import { RightIcon } from '../../otp-input-confirmation/src/assets';
import InfiniteScroll from 'react-infinite-scroller';
// Customizable Area End


export default class CreateHighlightStories  extends CreateHighlightStoriesController {

    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
    // Customizable Area Start


    renderHighlightPic=()=>{
        const {checked} = this.props
        return (
            <PicContainer style={{background:(this.conditionRemover(this.props.checked,"unset","#0B0B0B"))}}>
                <Box className={this.conditionRemover(this.props.checked,'lightContainer','container')}>
            <Box className='editContainer'>
             <Box className='headContainer'>
                 <Box data-testid="backBtn" onClick={this.backtoArchive}><img className='topIcon' src={this.conditionRemover(checked, darkArrowIcon, ArrowIcon)} /></Box>
                 <Box><Typography className='HeadingStyle'>Select cover</Typography></Box>
                 <Box data-testid="crossIcon" onClick={this.handleClose}><img className='topIcon' src={this.conditionRemover(checked, crossIcon ,lightCross)} /></Box>
             </Box> 

               <Box className='highlightImage'>
               <img  className="cover_image"src={this.state.coverPic||cover} alt='' />
                <Box className='selected_portiion'></Box>
               </Box>

               <Box className='buttonContainer'>
                 <Box className='add_image' component={"label"}>
                 <input data-testid="uploadImage" type='file' accept="image/jpeg, image/png" hidden onChange={this.handleChangeCoverPicture} data-test-id='uploadCoverImage' />
                    <img className='add_imageIcon' src={ImageIcon}/>
                 </Box>
                 <Box><img src={sharkTank} className='side_image'/> </Box> 
               </Box>

               <Box className='submit_container'>
                  <Box><Button disabled={this.state.coverPic===""} data-testid="doneBtn" onClick={this.addHighlightStory} className='doneBtn'>Done</Button></Box>
               </Box>

             </Box>
             </Box>

            </PicContainer>
        )
    }


    renderMedia=(storyAttributes:IStoryAttributes)=>{
        return(
            <Box className='imageContainer' >

          { storyAttributes.media.content_type.startsWith('image')? <img 
            className='image'
            src={storyAttributes.media.url}  
            />: 
            <ReactPlayer 
            className="react-player"
            url = {storyAttributes.media.url}
            />
            }
            <Box className='topPosition'>
              <Box className='dateContainer'>
              <Typography className='text_image'>{this.getDate(storyAttributes.created_at)}</Typography>
              </Box>
              </Box>
            <Box className='bottomPosition'>   
        
            {
            this.state.selectedStories.includes(storyAttributes.id)?
                <img src={ckeckedLogo} 
                    alt=""
                    data-testid="story"
                    onClick={()=>this.handleSelectedStory(storyAttributes.id)}
                />
                 : 
                <span onClick={()=>this.handleSelectedStory(storyAttributes.id)} data-testid="story" style={{ width: "20px", height: "20px", border: "1px solid #6B6B6B", display:'flex', borderRadius: "50%" }}></span>
           }
           </Box>
          </Box>
        )
    }

    renderHighlightName=()=>{
        return(
            <DialogContainer2 data-testid="highlightNameDialog" open={this.state.openDialog} onClose={this.handleClose}>
              <HighlightContainer style={{background:(this.conditionRemover(this.props.checked,"unset","#0B0B0B"))}}>
                <Box className={this.conditionRemover(this.props.checked,'lightContainer','container')}>
                <Box className="BoxContainer">
                 <Box className='headContent'>
                    <Typography className='headerText'>{this.conditionRemover(this.props.isEditable,"Edit highlight","New highlight")}</Typography>
                  </Box>
                 
                 <Box>
                 <CustomTextField 
                   name="name"
                   placeholder='Highlight Name'
                   value={this.state.name}
                   variant="outlined"
                   fullWidth
                   data-testid="highlight_name"
                   onChange={this.handleChangeName}
                  />
                 </Box>
                </Box>
                <Box className='button_alignment'>

                <Button
                    className={"buttonPassword"}
                    data-testid='highlight_name_next'
                    fullWidth
                    onClick={this.handleHighlightNext}
                    disabled={this.state.name.length===0}
                    type="submit"
                    endIcon={<StyledIcon> <img src={RightIcon}/></StyledIcon>}
                    >
                        Next
                    </Button>
                </Box>
                </Box>
              </HighlightContainer>
            </DialogContainer2>
        )
    }


    renderStories=()=>{
        return (
            <DialogContainer data-testid="Archive_story_modal" open={this.state.openArchivedStory} onClose={this.handleClose}> 
               {this.state.isArchive?this.renderHighlightPic():this.renderArchivedStoriesContainer()}
            </DialogContainer>
        )
    }


   renderArchived=()=>{
      return (
        <InfiniteScroll 
        pageStart={this.state.pagination}
        loadMore={this.getArchivedStories}
        hasMore={this.state.pagination<=this.state.totalPages}
        useWindow={false}
        loader={
        <div className={"loader"} key={0}> 
        {this.state.pagination<=this.state.totalPages&&<CircularProgress disableShrink />}
        </div>} 
       > 
    <Grid container spacing={0}>
      {
        this.state.stories.map((item)=>{
          return (
            <Grid item xs={4} style={{marginTop:5}} data-testid="archived_stories">
             {this.renderMedia(item.attributes)}
            </Grid>
          )
        })
      }
    </Grid>
    </InfiniteScroll>
      )
   }

   renderTablist=()=>{
    const  { activeIndex } = this.state
    return(
      <Box className={'tabsContainer'}>
      <Tabs
        classes={{ indicator:"indicator" }}
        textColor="primary"
        className='tabs_style'
        value={activeIndex}
        onChange={this.handleTabChange}
        data-testid="tabBtn"
      >
        <Tab label="Selected" value="Selected" className={'tabstyle'} />
        <Tab label="Stories" value="Stories" className={'tabstyle'} />
      </Tabs>
    </Box>
    )
  }

   renderEditArchived=()=>{
        return(
            <>
            {this.state.activeIndex==="Selected" && this.renderSelectedStories()}
            {this.state.activeIndex === "Stories"&& this.renderArchived()}
            </>
        )
    }

    renderSelectedStories=()=>{
        return(
            <Grid container spacing={0}>
            {

              this.state.userStories.map((item)=>{
                return (
                  <Grid item xs={4} style={{marginTop:5}} data-testid="archived_stories">
                   {this.renderMedia(item.attributes)}
                  </Grid>
                )
              })
            }
          </Grid>
        )
    }

    renderArchivedStoriesContainer=()=>{
        const {checked} = this.props
        return (
            <BoxContainer  style={{background:(this.conditionRemover(this.props.checked,"unset","#0B0B0B"))}}>
            <Box className={this.conditionRemover(this.props.checked,'lightContainer','container')}>
            <Box className='editContainer' data-testid="renderArchived">
             <Box className='headContainer'>
                 <Box data-testid="prev-btn" onClick={this.HighlightNameDialog}><img className='topIcon' src={this.conditionRemover(checked, darkArrowIcon, ArrowIcon)} /></Box>
                 <Box><Typography className='HeadingStyle'>Stories</Typography></Box>
                 <Box data-testid = "cross-btn"onClick={this.handleClose}><img className='topIcon' src={this.conditionRemover(checked, crossIcon ,lightCross)} /></Box>
             </Box> 
              {this.state.isEdit?
              <>  
              {this.renderTablist()}            
              <div className="EditStoryContainer">
                {this.renderEditArchived()}
              </div>
              </>

                 :
              <div className='storyContainer' >
                 {this.renderArchived()}
              </div>
              }
              <Box className='selected_story'>
                  <Box><Typography className='selectedCount'>{!(this.state.isEdit && this.state.activeIndex==="Selected") && `${this.state.selectedStories.length} Selected` }</Typography></Box>
                  <Box><Button data-testid="next-btn" onClick={this.handleOpenCover} className={"followBtn"} disabled={this.state.selectedStories.length===0}>Next</Button> </Box>
              </Box>
             </Box>   
             </Box>                                 
            </BoxContainer>
        )
    }


    conditionRemover = (condition: boolean, trueStatement: string, falseStatement: string) => {
        return condition ? trueStatement : falseStatement
    }
    // Customizable Area End
    render(){
    // Customizable Area Start
    return(
        <>   
        {this.renderStories()}
        {this.renderHighlightName()}   
        </>

    )
    // Customizable Area End    
    }
}
// Customizable Area Start

const BoxContainer = styled(Box)({
    display: 'flex',
    width: '100%',
    height:'90vh',
    overflow:'unset',
    borderRadius:10,
    maxHeight:'774px',
    justifyContent: 'center',
    alignItems: 'center',

    "& .tabstyle": {
        fontFamily: "Manrope",
        fontWeight: 600,
        fontSize: "16px",
        minWidth:'unset !important',
        width:'50%',
        textTransform: "capitalize" as "capitalize",
        borderBottom: "3px solid #35383F",
        '&.Mui-selected': {
          background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent' as "transparent",
        },
       
        '@media(max-width:1000px)':{
          width:'50%'
        },
      },

      "& .indicator": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        height: '2.9px',
      },

      "& .tabsContainer": {
        height: "35px",
        width:'100%',
        marginTop:-6
      },

    '& .container': {
        width: '100%',
        height: '100%',
        background: '#0B0B0B'
    },

    '& .lightContainer': {
      width: '100%',
      height: '100%',
  },


    "& .editContainer": {
        display: 'flex',
        gap: 8,
        flexDirection: 'column',
    },

    "& .headContainer": {
        display: 'flex',
        height: 48,
        padding:'10px 20px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .followBtn": {
        width: "65px",
        height: "25px",
        borderRadius: "10px",
        padding: "4px 8px 4px 8px",
        gap: "7px",
        fontSize: "12px",
        fontFamily: "Manrope",
        color: "white",
        fontWeight: 500,
        textTransform: "capitalize" as "capitalize",
        background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))",
        "&.MuiButton-root.Mui-disabled": {
            color: '#E9E9E9',
            opacity:0.64
          }
    },

    "& .HeadingStyle": {
        fontSize: 18,
        fontFamily: 'Manrope',
        fontWeight: 800
    },


    "& .imageContainer":{
       height:204,
       width:122,
       position:'relative'
    },

    "& .image":{
        height:204,
        objectFit:"cover",
        width:122
    },

    "& .bottomPosition":{
       position:'absolute',
       margin:12,
       right:0,
       bottom:0
    },

    "& .topPosition":{
      position:'absolute',
      margin:6,
      top:0
    },
    "& .dateContainer":{
      width:33,
      height:35,
      padding:'5px',
      borderRadius:5,
      background:'#222222'
    },

  "& .text_image": {
    fontFamily: "Manrope",
    fontSize: "12px",
    opacity:0.7,
    fontWeight: 700,
    color:'#fff'
   },

   "& .react-player":{
    width:'100% !important',
    height:'100% !important',
   },

   "& .storyContainer":{
      height:'calc(90vh - 150px)',
      maxHeight:'calc(774px - 150px)',
      overflow:'auto',
      bakgrounf:'#0B0B0B',
      padding:'10px 20px',

   },
   "& .selected_story":{
      padding:"10px 20px",
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
   },

   "& .selectedCount":{
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 500,

   },

   "& .EditStoryContainer":{
    height:'calc(90vh - 185px)',
    maxHeight:'calc(774px - 185px)',
    overflow:'auto',
    bakgrounf:'#0B0B0B',
    padding:'10px 20px',
   }


})


const DialogContainer2=styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: "497px",
        maxHeight: '288px',
        width: '100%',
        border: '1px solid #35383F',
        borderRadius: '10px',
    }
})

const DialogContainer= styled(Dialog)({

        '& .MuiDialog-paperWidthSm': {
            maxWidth: "412px",
            maxHeight: '774px',
            width: '90%',
            border: '1px solid #35383F',
            borderRadius: '10px',
            overflow:'unset',
        }
})


const PicContainer=styled(Box)({
    display: 'flex',
    width: '100%',
    height:'90vh',
    borderRadius:10,
    overflowX:'unset',
    overflowY:'auto',
    maxHeight:'607px',
    justifyContent: 'center',
    alignItems: 'center',

    "& .highlightImage":{
        position:'relative',
        display:'inline-block',
       padding:'10px 20px',
    },

    '& .container': {
        width: '100%',
        height: '100%',
        background: '#0B0B0B'
    },
    '& .lightContainer': {
        width: '100%',
        height: '100%',
    },
    
    "& .HeadingStyle": {
        fontSize: 18,
        fontFamily: 'Manrope',
        fontWeight: 800
    },

    "& .editContainer": {
        display: 'flex',
        gap: 8,
        flexDirection: 'column',
    },

    "& .add_imageIcon":{
       height:24,
       width:24
    },

    "& .side_image":{
      height:50,
      width:50,
      borderRadius:5,
    },

    "& .doneBtn": {
        width: "65px",
        height: "25px",
        borderRadius: "10px",
        padding: "4px 8px 4px 8px",
        gap: "7px",
        fontSize: "12px",
        fontFamily: "Manrope",
        color: "white",
        fontWeight: 500,
        textTransform: "capitalize" as "capitalize",
        background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))",
        "&.MuiButton-root.Mui-disabled": {
            color: '#E9E9E9',
            opacity:0.64
          }
    },

    "& .headContainer": {
        display: 'flex',
        height: 48,
        padding:'10px 20px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    "& .cover_image":{
        height:375,
        width:375
    },

    "& .submit_container":{
        padding:'10 20px 20px 20px',
        marginTop:12,
        display:'flex',
        justifyContent:'end'
    },

    "& .add_image":{
      height:50,
      width:50,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      borderRadius:5,
      border:'1px solid #fff',
    },

    "& .buttonContainer":{
      display:'flex',
      marginTop:12,
      justifyContent:'center',
      gap:10,  
    },

    "& .selected_portiion":{
        position:'absolute',
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background:'rgba(0, 0, 0, 0.5)',
        WebkitMaskImage: 'radial-gradient(circle, transparent 170px, rgba(0, 0, 0, 0.5) 100px)',
        maskImage:'radial-gradient(circle, transparent 170px, rgba(0, 0, 0, 0.5) 100px)',
        maskPosition:"0px -10px",
        WebkitMaskPosition:"0px -10px",
        maskRepeat:"no-repeat",
        zIndex: 1
    }
})


const HighlightContainer = styled(Box)({
    maxHeight: '497px',
    height: '497px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    '& .container': {
        width: '100%',
        height: '100%',
        background: '#0B0B0B'
    },

    '& .lightContainer': {
      width: '100%',
      height: '100%',
      background: '#FFF'
  },

    "& .HeadingStyle": {
        fontSize: 18,
        fontFamily: 'Manrope',
        fontWeight: 800
    },

    "& .BoxContainer":{
     padding:18,
     display:'flex',
     flexDirection:'column',
     gap:'40px'
     
    },

    "& .headContent":{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderBottom:'1px solid #222222',
        height:57
    },

    "& .buttonPassword": {
        borderRadius: "10px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        textTransform: "capitalize" as "capitalize",
        color: "#E9E9E9",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        marginTop: '22px',
        height: '46px',
        "&.MuiButton-fullwidth":{
            width:'122px'
        },
        "&.MuiButton-root.Mui-disabled": {
          color: '#E9E9E9',
          opacity:0.64
        }
      },


    "& .headerText":{
    fontFamily: 'Manrope',
    fontSize: '24px',
    fontWeight: 800,
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    },

    "& .button_alignment":{
        width:'100%',
        justifyContent:'center',
        display:'flex'
    },

})


const CustomTextField = styled(TextField)({
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Manrope',
    "& .MuiOutlinedInput-root": {
        borderRadius: '10px !important',
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8833FF'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8833FF !important'
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Manrope',
    },
    "& .MuiInputBase-input": {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Manrope',
    }

})

const StyledIcon = styled(IconButton)({
    padding:0,
    marginLeft:-4,
    "&.MuiIconButton-root:hover":{
     background:'unset'
    }
  })

// Customizable Area End