Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Followers";
exports.labelBodyText = "Followers Body";
exports.searchBoxText = "Search a follower";
exports.userListTitle = "User List";
exports.followingTitle = "Following";
exports.followTitle = "Follow";
exports.unFollowTitle = "UnFollow";
exports.ddTitle = "dd";
//custom
exports.labelTitleTextFollowing = "Following";
exports.labelTitleTextFollowers = "Followers";
exports.followersApiEndPoint = "bx_block_followers/follows/followers";
exports.followingApiEndpoint = "bx_block_followers/follows/following";
exports.unFollowApisApiEndPoint = "bx_block_followers/follows"
exports.addFollowersApiEndPoint = "bx_block_followers/follows"
exports.addFollowingApiEndPoint = "bx_block_followers/follows"
exports.getFollowersApiMethodType = "GET";
exports.getFollowingApiMethodType = "GET";
exports.deleteApiMethodType = "DELETE"
exports.postApiMethodType = "POST";
exports.userListEndPoint = 'account_block/accounts'
exports.userListFollowersEndPoint = '/bx_block_followers/follows/following'
exports.userListFollowingEndPoint = '/bx_block_followers/follows/followers'

exports.welcomeMesage = "Connect with your social media account";
exports.TwitterButton = "Continue with X (Twitter)";
exports.ConnectedTwitterButton = "Connected with X (Twitter)";
exports.InstagramButton = "Continue with Instagram";
exports.FacebookButton = "Continue with Facebook";
exports.skipButton = "Skip for Now"
exports.modelHead = "Connect with"
exports.modelSubHead = "Would you like to connect with"
exports.FollowText ="Follow"
exports.welcomeMesage2 = "Follow 1 or more accounts";
exports.nextBtn = "Next";




exports.btnExampleTitle = "CLICK ME";
exports.followersListEndPoint='account_block/accounts';
exports.followApiEndpoint='bx_block_followers/follows';
exports.unfollowUserEndpoint='bx_block_followers/follows';
exports.allow='Allow'

exports.getFollowersEndPoint = "/bx_block_followers/account_followers"
exports.getFollowingEndPoint = "bx_block_followers/account_following"
exports.loggedUserEndpoint ="account_block/accounts/logged_user"
exports.instaUrl='https://api.instagram.com/oauth/authorize?client_id=480938414425781&redirect_uri=https://arrowssocialnetwork1-466346-react.b466346.dev.eastus.az.svc.builder.cafe/Followers/&scope=user_profile,user_media&response_type=code';
exports.twitterUrl='https://twitter.com/i/oauth2/authorize?response_type=code&client_id=RHNONlFhQUFTWTk1N0FGV2JkNUI6MTpjaQ&redirect_uri=https://arrowssocialnetwork1-466346-react.b466346.dev.eastus.az.svc.builder.cafe/Followers&scope=tweet.read%20users.read%20follows.read%20follows.write&state=state&code_challenge=challenge&code_challenge_method=plain';
exports.uploadPictureApiEndPoint='account_block/update_profile';
exports.uploadPictureApiMethod='PATCH';
exports.twitterEndPoint='account_block/accounts/twitter/link_social?platform=desktop'
// Customizable Area End