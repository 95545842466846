// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, ThemeProvider, TextField, InputAdornment, Button, Typography, createTheme, CircularProgress, styled } from "@material-ui/core";
import RightSideBarController, {
	Props,
} from "./RightSideBarController";
import { callDark, callLight, profileLogo, searchDark, searchLight } from "./assests";
import { verifyImg } from "../../../components/src/utils";

const lightTheme = createTheme({
	palette: {
		type: "light",
		primary: {
			main: "#3858E3",
		},
		secondary: {
			main: 'rgba(0,0,0,0.75)'
		}
	}
});

const darkTheme = createTheme({
	palette: {
		type: "dark",
		primary: {
			main: "#3858E3",
		},
		secondary: {
			main: "rgba(255,255,255,0.75)"
		}
	}
})

export class RightSideBar extends RightSideBarController {
	constructor(props: Props) {
		super(props);
	}

	renderContent = () => {
		const classes = this.props.classes

		if (this.state.isLoading) {
			return (
				<Box className={classes.loadingWrapper}>
					<CircularProgress size={32} style={{ color: '#8a33f0' }} />
				</Box>
			)
		}

		if (this.state.suggestFollowPeople.length === 0) {
			return (
				<Box className={classes.loadingWrapper}>
					<Typography className={classes.noSuggestionTitle}>
						No suggestion
					</Typography>
				</Box>
			)
		}

		return (
			<>
				{this.state.suggestFollowPeople.map((person) => {
					return (
						<Box key={person.id} style={{ display: "flex", justifyContent: "space-between" }}>
							<Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
								<img
									src={person.profile_photo || profileLogo}
									alt="img"
									className={classes.postImage}
								/>
								<Box className={classes.headconatin}>
									<Box className={classes.ContentHead}>
										<Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
											<Typography className={classes.textLarge}>{person.full_name}</Typography>
											<img src={verifyImg(person.user_subscription)} alt="" />
										</Box>
										<Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
											<Typography className={classes.userName}>{person.user_name}</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							{!person.is_following && (
								<Button
									data-test-id='followBtn'
									className={classes.followBtn}
									onClick={() => this.handleFollow(person.id)}
								>
									Follow
								</Button>
							)}
						</Box>
					)
				})}
			</>
		)
	}

	render() {
		const { classes, checked } = this.props

		return (
			<ThemeProvider theme={this.props.checked ? lightTheme : darkTheme}>
				<Box className={classes.container}>
					<Box className={classes.sideHeader}>
						<TextField
							variant="outlined"
							placeholder="Search"
							className={classes.searchField}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<img src={checked ? searchDark : searchLight} alt="" />
									</InputAdornment>
								),
							}}
						/>
						<img src={checked ? callDark : callLight} alt="" />
					</Box>

					<Box className={classes.contentContainer}>
						<Box
							className={classes.subscriptionBox}
							style={{ borderColor: checked ? '#222222' : '#1D1D1D' }}
						>
							<Typography className={classes.subscriptiontext}>
								Choose the right subscription for you!
							</Typography>
							<Typography className={classes.subcriptionBody}>
								Subscribe to unlock new features and if eligible, receive a share of ads revenue.
							</Typography>
							<Button className={classes.continueBtn}>Continue</Button>
						</Box>

						<Box
							className={classes.whathappiningBox}
							style={{ borderColor: checked ? "#222222" : "#1D1D1D" }}
						>
							<Typography className={classes.textLarge}>
								Whats Happening
							</Typography>
							{Array.from({ length: 3 }).map((val) => (
								<Box style={{ display: "flex", flexDirection: "column", rowGap: "4px" }}>
									<Typography className={classes.subcriptionBody}>Trending in India</Typography>
									<Typography className={classes.textLarge}>#shardot</Typography>
									<Typography className={classes.subcriptionBody}>19.5k posts</Typography>
								</Box>
							))}
							<Typography className={classes.showmore}>Show more</Typography>

						</Box>

						<Box
							className={classes.whathappiningBox}
							style={{ borderColor: checked ? "#222222" : "#1D1D1D", maxHeight: "299px" }}
						>
							<Typography className={classes.textLarge}>Who to follow</Typography>
							<FollowContainerStyled>
								<Box className={`followContainer ${this.props.checked && "followContainer-lightTheme"}`}>
									{this.renderContent()}
								</Box>
							</FollowContainerStyled>
						</Box>
					</Box>
				</Box>
			</ThemeProvider>
		)
	}
}

const styles = createStyles({
	container: {
		height: "calc(100vh - 20px)",
		maxWidth: "420px",
		paddingTop: "20px",
		overflow: "scroll" as "scroll",
		scrollbarWidth: "none" as "none",
		"&::-webkit-scrollbar": {
			display: "none" as "none",
		},
	},
	sideHeader: {
		display: "flex",
		gap: "21px",
		marginBottom: "28px"
	},
	searchField: {
		'& .MuiOutlinedInput-root': {
			borderRadius: "10px",
			'&.Mui-focused fieldset': {
				border: "1px solid #7A2BF5",
			},
		},
		width: "80%"
	},
	contentContainer: {
		display: "flex",
		flexDirection: "column",
		rowGap: "18px",
		paddingBottom: '40px',
	},
	subscriptionBox: {
		borderRadius: "12px",
		padding: "12px 20px",
		gap: "12px",
		display: "flex",
		flexDirection: "column" as "column",
		border: "0.5px solid #1D1D1D",
	},
	subscriptiontext: {
		fontSize: "18px",
		fontFamily: "Manrope",
		fontWeight: 800,
		lineHeight: "21px",
		background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
		backgroundClip: 'text',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent' as "transparent"
	},
	subcriptionBody: {
		fontSize: "14px",
		fontFamily: "Manrope",
		fontWeight: 500,
		lineHeight: "21px",
	},
	continueBtn: {
		background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
		Padding: "6px 20px 6px 20px",
		gap: "7px",
		borderRadius: "10px",
		display: "flex",
		width: "35%",
		color: "white",
		fontSize: "12px",
		fontFamily: "inter",
		fontWeight: 500,
		textTransform: "none" as "none",
	},
	whathappiningBox: {
		borderRadius: "12px",
		padding: "12px 20px",
		gap: "12px",
		display: "flex",
		flexDirection: "column" as "column",
		border: "0.5px solid #222222",
	},
	textLarge: {
		fontSize: "18px",
		fontFamily: "Manrope",
		fontWeight: 800,
		lineHeight: "21px",
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		}
	},
	loadingWrapper: {
		display: "flex",
		justifyContent: "center",
	},
	showmore: {
		fontSize: "14px",
		fontFamily: "Manrope",
		fontWeight: 500,
		lineHeight: "21px",
		background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
		backgroundClip: 'text',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent'
	},
	userName: {
		fontSize: "12px",
		fontFamily: "Manrope",
		fontWeight: 500,
		lineHeight: "14px",
		whiteSpace: "nowrap" as "nowrap",
		color: "#6B6B6B"
	},
	followBtn: {
		width: "65px",
		height: "25px",
		borderRadius: "10px",
		padding: "4px 8px 4px 8px",
		gap: "7px",
		fontSize: "12px",
		fontFamily: "Manrope",
		color: "white",
		fontWeight: 500,
		textTransform: "capitalize" as "capitalize",
		background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
	},
	postImage: {
		height: "35px",
		width: "35px",
		borderRadius: "50%",
		objectFit: "cover" as "cover"
	},
	ContentHead: {
		display: "flex",
		flexDirection: "column" as "column",
		gap: "5px",
	},
	headconatin: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between"
	},
	noSuggestionTitle: {
    fontSize: "24px",
    fontWeight: 800,
    fontFamily: "Manrope",
    backgroundClip: 'text',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
  },
});

const FollowContainerStyled = styled(Box)(() => ({
	// padding: "12px 12px 12px 0",
	height: "270px",
	"& .followContainer": {
		display: "flex",
		flexDirection: "column" as "column",
		rowGap: "12px",
		height: "100%",
		overflowY: "auto" as "auto",
	},
	"& .followContainer.followContainer-lightTheme": {
		'&::-webkit-scrollbar': {
			width: '8px',
		},
		'&::-webkit-scrollbar-track': {
			background: '#f1f1f1',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#888',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#555',
		},
	}
}))

export default withStyles(styles)(RightSideBar);
// Customizable Area End
