import React from 'react'
// Customizable Area Start 
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    Fade,
    Button,
    Backdrop,
    styled
} from '@material-ui/core'
import Loader from '../../../components/src/Loader.web';
import { crossWhiteIcon, crossBlackIcon, radioCheckedDark, radioUnChecked, radioCheckedLight, arrowRight24White, logoXlIcon } from "../../settings2/src/assets";
import ReportUserModalController,{Props} from './ReportUserModalController.web';
import { IReportIssue } from './ProfileController.web';
const inputStyles = {
    fade: {
      border: '1px solid #5B5B5B',
      borderRadius: '10px',
      display: 'flex',
    },
    lightBackdrop: {
      backgroundColor: '#FFFFFF',
      opacity: 0.9
    },
    darkBackdrop: {
      backgroundColor: '#0E0E0E',
      opacity: 0.8
    }
  }
const webStyle = {
    modalHead: {
      display: "flex",
      flexDirection: "row" as "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: '539px',
    },
    ModalHeadtitle: {
      fontSize: "18px",
      fontWeight: 800,
      fontFamily: "Manrope"
    },
    nextBtn: {
      width: "72px",
      height: "32px",
      borderRadius: "10px",
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      color: "white",
      fontSize: "14px",
      fontWeight: 600,
      display: "flex",
      flexDirection: "row" as "row",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Manrope"
    },
  
    submitBtn: {
      width: "160px",
      height: "48px",
      borderRadius: "10px",
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      color: "white",
      fontSize: "14px",
      fontWeight: 600,
      display: "flex",
      flexDirection: "row" as "row",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Manrope",
      marginTop: "15px"
    },
  
  };
// Customizable Area End


export default class ReportUserModal  extends ReportUserModalController {

    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
    // Customizable Area Start
    renderMainCatList = () => {
        const { checked } = this.props;
        const checkedradioIcon = !checked ? radioCheckedDark : radioCheckedLight;
    
        return (
          <List className={"listReportPost"}>
            {this.props.reportPostMainList.map((each:IReportIssue, index: number) =>
            (<ListItem alignItems="center" className={"listItemReportPost"} button data-test-id={`main-cat-${index}`} key={`setingsCat${index}`} onClick={() => this.props.setReportPostMainCat(each.attributes.id)} >
              <ListItemText className="label-container" primary={each.attributes.title} secondary={each.attributes.description} />
              <ListItemIcon className="radio-icon">
                <img src={this.props.reportCatId === each.attributes.id ? checkedradioIcon : radioUnChecked} className={"iconImage"} />
              </ListItemIcon>
            </ListItem>))}
          </List>
        )
      }
    
      renderSubCatList = () => {
        const { checked } = this.props;
        const checkedradioIcon = !checked ? radioCheckedDark : radioCheckedLight;
    
        return (
          <List className={"listReportPost"} data-testid="sublist">
            {this.props.reportPostSubList&&this.props.reportPostSubList.map((each:IReportIssue, index: number) => (<ListItem alignItems="center"
              className={"listItemReportPost"}
              button
              data-test-id={`sub-cat-${index}`}
              key={`setingsCat${index}`}
              onClick={() => this.props.setReportPostSubCat(each.attributes.id)}
            >
    
              <ListItemText className="label-container" primary={each.attributes.title} secondary={each.attributes.description} />
              <ListItemIcon className="radio-icon">
                <img src={this.props.reportSubCatId === each.attributes.id ? checkedradioIcon : radioUnChecked} className={"iconImage"} />
              </ListItemIcon>
            </ListItem>))}
          </List>
        )
      }

      renderReportPostSubmit = () => {
        return (
          <div className="renderReportPostSubmitContainer">
            <img className="cursorPointer logo" src={logoXlIcon} alt="cross" />
            <h5 className="font24 txtGradiant submittedTitle">Submitted</h5>
            <p data-testid="submitContent" className="description">Thanks for helping make Arrows better for everyone.We know it wasn’t easy, so we appreciate you taking the time to answer those questions.</p>
            <Button onClick={this.props.closeReportPostModal} className="submitButton" data-testid="submitButton" style={webStyle.submitBtn}>
              <p className="label txtCapitalize">Done</p>
              <span className="icon"><img src={arrowRight24White} className={"iconImage"} /></span>
            </Button>
          </div>
        )
      }
   

      renderReportModal = () => {
        const { checked } = this.props;
        let btnOpacity = "unset";
        if (this.props.reportCurrentView === "main" && this.props.reportCatId === undefined) {
          btnOpacity = "50%";
        }
        if (this.props.reportCurrentView !== "main" && this.props.reportSubCatId === undefined) {
          btnOpacity = "50%";
        }
        return (
          <Modal
            className={`reportPostModal dark-${!this.props.checked}`}
            open={this.props.showReportPostModal}
            closeAfterTransition
            data-testid="ReportUserPostModal"
            BackdropComponent={Backdrop}
            BackdropProps={{
              style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop
            }}
          >
            <Fade
              in={this.props.showReportPostModal}
              style={inputStyles.fade}
            >
              {this.props.reportCurrentView === "submit" ? this.renderReportPostSubmit() :
                <BoxContainer>
                <div 
                  data-test-id="reportPostBackdrop" 
                  className="reportPostModalContainer"
                  style={{background: checked ? "#FFF" : '#000'}}
                >
                  <div className="modalHead" style={webStyle.modalHead}>
                    <span onClick={() => this.props.closeReportPostModal()} data-test-id="closereportPostModal">
                      <img className="cursorPointer" src={checked ? crossBlackIcon : crossWhiteIcon} alt="cross" />
                    </span>
                    <span data-test-id="reportPostTxt" style={webStyle.ModalHeadtitle}>Gathering info</span>
                    <Button data-testid="next" onClick={this.props.changeReportView} style={{ ...webStyle.nextBtn, opacity: btnOpacity }} className="submitButton"> Next</Button>
                  </div>
                  <h2>What type of issue are you reporting?</h2>
                  <Loader loading={this.props.reportPostLoading} />
                  {this.props.reportCurrentView === "main" ? this.renderMainCatList() : this.renderSubCatList()}
                </div>
                </BoxContainer>
                }
            </Fade>
          </Modal>
        )
      }

    // Customizable Area End
    render(){
    // Customizable Area Start
    return(
       <Box>
         {this.renderReportModal()}
       </Box>
    )
    // Customizable Area End    
    }
}
// Customizable Area Start
const BoxContainer = styled(Box)({
 width:'100%',
})
// Customizable Area End