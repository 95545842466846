import React from "react";
import { toast } from "react-toastify";
import { BlockComponent } from '../../framework/src/BlockComponent';
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from '../../framework/src/RunEngine';
import { IBlock } from "../../framework/src/IBlock";
import { removeStorageData } from "../../framework/src/Utilities";
import TrialExpiredModal from "./TrialExpiredModal";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";

export interface Props {
  isLoggedIn: boolean;
  navigation: any;
  path: string
}

export interface S {
  isTrialExpiredState: boolean
}

export default class SessionGate extends BlockComponent<Props, S, {}> {
  isTrialExpired: boolean = false

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ]

    this.state = {
      isTrialExpiredState: false
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && (responseJson?.errors?.[0] === 'Invalid token' || responseJson?.errors?.[0] === 'Token has Expired')) {
        if (this.props.path !== '/') {
          toast.info(responseJson?.errors?.[0]);
          this.handleLogout();
        }
      }
      
      const isNotSubsciptionScreen = this.props.path !== '/AutomaticRenewals'
      const isLoggedUserApi = responseJson?.data?.type === 'logged_user'
      if (isNotSubsciptionScreen && !this.isTrialExpired && isLoggedUserApi) {
        const endDateStr = responseJson?.data?.attributes?.subscription_details?.end_date
        if (endDateStr) {
          const endDate = new Date(endDateStr);
          const currentDate = new Date();
          if (currentDate > endDate) {
            this.isTrialExpired = true
            this.setState({isTrialExpiredState: true})
          } 
        }
      }
    }
  }

  handleLogout = async () => {
    try{
      Object.keys(localStorage).forEach(async (key) => {
        await removeStorageData(key);
      });
      this.props.navigation.history.replace("/")
      await CometChatUIKit.logout();
    } catch(error) {
      console.error("Handle logout in session gate failed with error ", error)
    }
  }

  render() {
    return (
      <>
        {this.props.children}
        <TrialExpiredModal
          open={this.state.isTrialExpiredState}
          onClose={() => this.setState({ isTrialExpiredState: false })}
        />
      </>
    )
  }
}