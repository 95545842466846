import React from "react";

// Customizable Area Start
import {
  Button,
  Paper,  
  ThemeProvider,
  Box,
  Grid,
  Typography,
  withStyles,
  createTheme,
  Dialog,
  IconButton,
  Table,
  TableRow,
  TableHead,
  TableCell,
  styled,
  TableContainer,
} from "@material-ui/core";
import {lightThemeBg,darkThemeBg} from "../../email-account-registration/src/assets";
import { FacebookIcon, InstagramIcon, leftArrow, leftArrowDark, logo, defualtUserAvatar, TwitterIcon, TwitterIconLight, RightIcon } from "./assets";
import FacebookLogin from "react-facebook-login";


// Customizable Area End

// Customizable Area Start
import FollowersController, { Props, configJSON } from "./FollowersController";

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})
// Customizable Area End

export default class Followers extends FollowersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  iconRender =() =>{
    if(this.state.modelData.type =="Twitter"){
      return   !this.props.checked? TwitterIcon:TwitterIconLight
    }else if(this.state.modelData.type =="Facebook"){
      return  FacebookIcon
    }else if(this.state.modelData.type =="Instagram"){
      return  InstagramIcon
    }
  }

  tableRender =(classes:{[key:string]:string}) =>{
    return  <TableContainer className={this.props.checked?classes.tableContainer:classes.tableContainerDark}>
    <Table className={classes.table}>
    {this.state.followers.map((item) => (
      <TableHead key={item.id}>
        <TableRow className={classes.tableHead}>
          <TableCell className={classes.startTableCellContent}>
            <Box className={classes.profileContainer}>
              <img src={item.profilePhoto!==null? item.profilePhoto:defualtUserAvatar} className={classes.profileImage} />
              <Box style={{display:'flex',flexDirection:'column',gap:3}}>
                <Typography data-test-id={`name${item.id}`} className={this.props.checked?classes.nameDark:classes.name}>
                  {item.name}{" "}
                  {this.renderVerifyIcon({
                    user_subscription:
                      item.subscription,
                  })}{" "}
                  {this.renderOwnershipIcon({
                    user_ownership:
                      item.ownership,
                  })}
                </Typography>
                <Typography  className={classes.description1}>
                  {item.description} | {this.getFollowers(parseInt(item.followers))}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell className={classes.endTableCellContent}>
            {!item.isfollowing?<Button data-test-id='followBtn1' className={classes.profileButton} onClick={()=>{this.handleFollow((item.id).toString())}}>
                 {configJSON.followTitle}                         
            </Button>:<Button data-test-id='followingBtn1' className={this.props.checked? classes.profileButtonFollowing:classes.profileButtonFollowingLight} onClick={()=>{this.handleFollowing(item.id)}} >
            {configJSON.labelTitleTextFollowing}                         
            </Button>}
          </TableCell>
        </TableRow>
       </TableHead>
    ))}
  </Table>
  </TableContainer>
  }

  socialLogin=(classes:{[key:string]:string})=>{
    const faceBook=this.state.modelData.type === 'Facebook'? 
    <Box className={classes.light}>
    <FacebookLogin
      appId={process.env.REACT_APP_FB_ID || ''}
      data-test-id='facebook'
      fields="name,email,picture"
      textButton='Allow'
      callback={this.responseFacebook}
      autoLoad={false}>
    </FacebookLogin>
  </Box>: <Box className={classes.instButton} data-test-id='twitter'onClick={this.handleTwitterLogin}>{configJSON.allow}</Box>
    return this.state.modelData.type === 'Instagram' ?
    <a data-test-id='instagram' className={classes.instButton} href={configJSON.instaUrl}>
      {configJSON.allow}</a>
      : faceBook
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes}=this.props    

      const  dialogRender =()=>{
          return <Dialog data-test-id="dialog-allow-connect" onClose={() => {
               this.setState({
                 isModalOpen: false,
               });
              }} open={this.state.isModalOpen}  classes={{paperWidthSm:conditionShort(!this.props.checked,classes.model,classes.modelLight)}} BackdropProps={{className:conditionShort(!this.props.checked,classes.backdropsAtyleDark,classes.backdropsAtyle)}} 
         >
            {  this.state.modelData.type !== "Congratulation"?
            <Grid container justifyContent="center" alignItems="center" direction="column" style={{
                    padding: "50px 60px",                                
              }}>
               <Typography style={{                                    
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',                                    
                fontFamily: 'Manrope',
                fontSize: "24px",
                fontStyle: 'normal',
                fontWeight: 800,
                lineHeight: '28px',
                letterSpacing: '-0.414px',
                marginBottom: "18px"
            }}>
             <img src={this.iconRender()} className={classes.Icons} alt="" />   {configJSON.modelHead}{"  "}<span style={{fontWeight:700}}>{this.state.modelData?.type}</span>
            </Typography>
            <Typography style={{
                fontFamily: 'Manrope',
                fontStyle: 'normal',
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: '19px',
                marginBottom: "32px",
                textAlign: "center",
                width:"200px"
            }}>
                {configJSON.modelSubHead}{" "}<span style={{fontWeight:700}}>{this.state.modelData?.type}?</span>
            </Typography>

            <Box style={{display:"flex" ,gap:"16px"}}>
            <Button fullWidth variant="contained" color="primary" data-test-id="cancleBtn" style={{width:"200px"}} onClick={()=>this.setState({
               isModalOpen: false
             })} className={`${conditionShort(this.props.checked,classes.buttonStyleDArk1 ,classes.buttonStyle1)}`} >
              <Box component='span' className={classes.colorfullText}>
              Cancel 
               </Box>
               
              </Button>
                  {this.socialLogin(classes)}
            </Box>                      
            </Grid> : <Grid container justifyContent="center" alignItems="center" direction="column" style={{
                    padding: "50px 60px",                                
              }}>
                <IconButton style={{
                     color: conditionShort(this.props.checked,"black","#fff"),
                     height: "44px",
                     width: "40px",
                     borderRadius: "8px",                     
                     padding:"10px",
                     marginBottom: "-43px",
                     marginLeft:"-370"
                       }} disableRipple disableFocusRipple disableTouchRipple data-test-id="backBtn2" onClick={()=>this.setState({isFollowers:true,isModalOpen:false})} >
                      <img src = {conditionShort(this.props.checked,leftArrowDark,leftArrow)}/>
                </IconButton>
                  <img src={logo} className={classes.Icons} alt="" style={{width:"54px",height:"53px",marginBottom:8}} />
               <Typography style={{                                
                fontStyle: 'normal',
                fontWeight: 800,
                lineHeight: '27x',
                letterSpacing: '-0.414px',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',                                    
                fontFamily: 'Manrope',
                fontSize: "24px",
                width:"327px",
                textAlign: "center",           
            }}>
              Congratulations! 
            </Typography>
            <Typography style={{                                
                textAlign: "center" ,
                marginBottom:"10px" ,
                width:"337px",             
            }} className={classes.arrowEndText2}>
              Ready to send some Arrows?
            </Typography>
           

            <Typography style={{
                fontFamily: 'Manrope',
                fontStyle: 'normal',
                lineHeight: '19px',
                fontSize: "16px",
                fontWeight: 600,
                marginBottom: "18px",
                textAlign: "center",
                width:"327px"
            }}>
                Your profile has been created!
            </Typography>

            <Box style={{display:"flex" }}>
            <Button fullWidth variant="contained" color="primary"  style={{width:"319px",padding:"15px 30px" ,color:"#fff",background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" }} 
               className={conditionShort(this.props.checked,classes.buttonStyleDArk ,classes.buttonStyle)} data-test-id="navigateBtn" onClick={this.navigateToDashboard} endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>}>
              Go to Dashboard
              </Button>
            </Box>                      
            </Grid>}
           </Dialog>
       }

       const conditionShort = (condition:boolean,trueStatement:any,falseStatement:any) =>{
        return condition ?  trueStatement :falseStatement
       }

     

    return (
      <>       
         <ThemeProvider theme={ conditionShort(this.props.checked , commonLightTheme,commonDarkTheme)}>
           <Paper className={conditionShort(this.props.checked, classes.lightColor,classes.darkColor)}>
            <Box className={conditionShort(!this.props.checked, classes.background,classes.lightBackground)}>
            <Grid container className={classes.gridContainer}>
              <Grid item className={classes.imageGridItem} md={5} sm={6} lg={6}>
              </Grid>           
             <Grid item xs={10} md={4} sm={6} lg={4} className={classes.formContainer}>
             
               <IconButton style={{
                     color: conditionShort(this.props.checked,"black","#fff"),
                     height: "44px",
                     width: "40px",
                     borderRadius: "8px",
                     padding:"10px",
                     marginBottom:conditionShort(this.state.isFollowers,"40px","0px"),
                     background: conditionShort(this.props.checked,"var(--Neutrals-Cool-gray-50, #E9E9E9)","var(--Basic-Black, #222)")
                       }} disableRipple disableFocusRipple disableTouchRipple data-test-id="backBtn" onClick={this.navigateToOwner}>
                    <img src = {conditionShort(this.props.checked,leftArrowDark,leftArrow)}/>
                </IconButton>
 
            {!this.state.isFollowers? <> <Box className={classes.head}>
                <Typography  data-test-id='welcome'>
                  <Box component='span' className={classes.arrowEndText}>
                  {configJSON.welcomeMesage}
                 </Box>
                </Typography>
               </Box>         
             
            <Box className={classes.formBox}>  
            <Button style={conditionShort(this.state.isContinue.Twitter,{color: "#FFF" ,background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" },{})} className={conditionShort(this.props.checked,classes.buttonStyleDArk ,classes.buttonStyle)} onClick={()=>{this.setState(()=>this.handleSocialMediaModel("Twitter"))}} fullWidth 
                    data-test-id='twitterBtn'>
                 <img src={conditionShort(!this.props.checked,TwitterIcon,TwitterIconLight)} alt="" className={classes.Icons} />    {this.state.twitterUserName? configJSON.ConnectedTwitterButton : configJSON.TwitterButton}
            </Button> 

            <Button className={conditionShort(this.props.checked,classes.buttonStyleDArk ,classes.buttonStyle)} onClick={()=>{this.setState(()=>this.handleSocialMediaModel("Instagram"))}} fullWidth 
                  style={conditionShort(this.state.isContinue.Instagram,{color: "#FFF" ,background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" },{})}
                  data-test-id='instaBtn' >
                <img src={InstagramIcon} alt="" style={conditionShort(this.state.isContinue.Instagram,{fill:'white', filter: 'brightness(0) invert(1)',opacity:0.8,padding:"4px"},{padding:'4px'})} className={classes.Icons} />    {configJSON.InstagramButton}
            </Button> 

            <Button className={conditionShort(this.props.checked,classes.buttonStyleDArk ,classes.buttonStyle)} onClick={()=>{this.setState(()=>this.handleSocialMediaModel("Facebook"))}} fullWidth 
                    style={conditionShort(this.state.isContinue.Facebook,{color: "#FFF" ,background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" },{})}
                   data-test-id='fbBtn' >
                <img src={FacebookIcon} alt="" className={classes.Icons} />    {configJSON.FacebookButton}
            </Button> 
            </Box> 

            <Box className={classes.footer}>  

            <Box style={{display:"flex" ,justifyContent:"space-between" ,gap:"20px"}}>
                    <Button fullWidth variant="contained"  style={{width:"200px"}} onClick={this.handleSkip} className={conditionShort(this.props.checked,classes.buttonStyleDArk ,classes.buttonStyle)}>
                    <Box component='span' data-test-id='skip'  className={classes.colorfullText}>
                       {configJSON.skipButton} 
                      </Box> 
                      </Button>
                     <Button disabled={!(Object.values(this.state.isContinue).some(value => value === true))} fullWidth variant="contained"  data-test-id="continue"  onClick={this.handleContinue}    style={{width:"200px",color: "#FFF" ,background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" }} endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>} className={conditionShort(this.props.checked,classes.buttonStyleDArk ,classes.buttonStyle)}>
                               Continue
                      </Button>
              </Box> 
            </Box>  </>:              
            <Grid item xs={12} md={10} className={classes.tableWrapper}>
            <Typography  data-test-id='welcome'>
              <Box component='span' className={classes.arrowEndText}>
              {configJSON.welcomeMesage2}
             </Box>
            </Typography>
            <Typography  data-test-id='welcome' style={{marginTop:"10px"}}  className={conditionShort(this.props.checked,classes.nameDark,classes.name)}>
              You may be interested in
            </Typography>
                 {this.tableRender(classes)}
                <Box style={{display:"flex" ,justifyContent:"space-between" ,gap:"20px"}}>
                    <Button fullWidth variant="contained" onClick={()=>this.handleModel("Congratulation")}  style={{width:"200px"}} className={conditionShort(this.props.checked,classes.buttonStyleDArk ,classes.buttonStyle)}>
                    <Box component='span' data-test-id="skipBtn" className={classes.colorfullText}>
                       {configJSON.skipButton} 
                      </Box> 
                      </Button>
                     <Button disabled={!this.state.isEnabled} fullWidth variant="contained" data-test-id="nextBtn"  onClick={()=>this.handleModel("Congratulation")}  style={{width:"200px",color: "#FFF" ,background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" }} endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>} className={conditionShort(this.props.checked,classes.buttonStyleDArk ,classes.buttonStyle)}>
                               {configJSON.nextBtn}
                      </Button>
              </Box>                 
            </Grid> 
              }
              {dialogRender()}

            
           </Grid>
        </Grid> 
            </Box>
           </Paper>
      </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  }
});

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    },
    info: {
      main: "#222222",
    },
    warning: {
      main: "#78716C",
    },
   }
  });

const commonDarkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      },
      info: {
        main: "#FFFFFF",
      },
      warning: {
        main: "#78716C",
      },
     }
    });

    const styles = {
      instButton:{
        background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        width:"200px",
        height:'48px',
        borderRadius:'10px',
        border:'none',
        color:"#fff",
        fontWeight:700,
        textTransform:'capitalize' as 'capitalize',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontFamily: "Manrope",
        fontSize: "14px",
        cursor:'pointer',
        textDecoration:'none'
      },
      light:{
        cursor:'pointer',
        '& .kep-login-facebook':{
          background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
          width:"200px",
          height:'48px'
        },
        "& .kep-login-facebook.metro":{
          borderRadius:'10px',
          border:'none',
          color:"#fff",
          fontWeight:700,
          textTransform:'capitalize' as 'capitalize',
          fontFamily:'Manrope',
          fontSize:'14px',
          paddingTop:'14px'
        },
        
      },
        model:{
          background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #0E0E0E ,#5B5B5B)",
          borderRadius: "10px",
          backgroundClip: "padding-box, border-box",
          backgroundOrigin: "padding-box, border-box",
          border: "1px solid transparent",
          backdropFilter: "blur(24.5px)",
          boxShadow:"none"          
      },
      colorfullText: {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        fontSize: "14px",
        fontStyle: "normal" as 'normal',
        fontWeight: 700,
        WebkitTextFillColor: 'transparent', 
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',  
        fontFamily: "Manrope",
        textTransform:"none" as "none"
  },
        modelLight:{
          borderRadius: "10px",
          border: "1px solid transparent",
          background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #dfd6d6 ,#5B5B5B)",
          backgroundClip: "padding-box, border-box",
          backgroundOrigin: "padding-box, border-box",
          backdropFilter: "blur(24.5px)",
          boxShadow:"none"
        },
        backdropsAtyle:{
          background:"rgba(255, 255, 255, 0.9)", 
          backdropFilter: "blur(1px)",
        },
        backdropsAtyleDark:{
          background: "rgba(8,4,8,0.7) !important" ,
          backdropFilter: "blur(1px)",
        },
      arrowEndText: {
          background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
          fontFamily: "Manrope",
          fontSize: "31px",
          fontStyle: "normal" as 'normal',
          fontWeight: 800,
          WebkitTextFillColor: 'transparent', 
          WebkitBackgroundClip: 'text',
          backgroundClip: 'text',
          lineHeight: `32px`,
          letterSpacing:` -0.155px`,          
    },
    arrowEndText2: {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      fontFamily: "Manrope",
      fontSize: "24px",
      fontStyle: "normal" as 'normal',
      fontWeight: 800,
      WebkitTextFillColor: 'transparent', 
      WebkitBackgroundClip: 'text',
      backgroundClip: 'text',
      lineHeight: `27px`,
      letterSpacing:` -0.155px`,          
},
    profileButton: {
      textTransform: "capitalize" as "capitalize",
      background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      color: "#FFFFFF",
      fontWeight: 500,
      fontFamily: "Manrope",
      fontSize: "clamp(12px,2vw,14px)",
      "&:hover": {
        background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      },
      "& .MuiButton-label":{
        padding:"6px 20px"
      },
      borderRadius: "10px",
      height: "32px",
      width: "80px",
      
    },
    profileButtonFollowing: {
      textTransform: "capitalize" as "capitalize",
      backgroundColor: "#E9E9E9",
      color: "#222222",
      fontWeight: 500,
      fontFamily: "Manrope",
      fontSize: "clamp(12px,2vw,14px)",
      "&:hover": {
        backgroundColor: "#E9E9E9",
      },
      "& .MuiButton-label":{
        padding:"6px 20px"
      },
      borderRadius: "10px",
      height: "32px",
      width: "80px",
      
    },
    profileButtonFollowingLight:{
      textTransform: "capitalize" as "capitalize",
      backgroundColor: "#222",
      color: "#FFFFFF",
      fontWeight: 500,
      fontFamily: "Manrope",
      fontSize: "clamp(12px,2vw,14px)",
      "&:hover": {
        backgroundColor: "#222",
      },
      "& .MuiButton-label":{
        padding:"6px 20px"
      },
      borderRadius: "10px",
      height: "32px",
      width: "80px",

    },
    endTableCellContent:{
     borderBottom:"none !important",
     padding:"0px"
    },
    tableWrapper:{
      width:"420px%"

    },
    tableContainerDark: {
      maxHeight: "50vh",
      overflowY: "auto"as "auto",
      margin: "40px 0px",
      width: "100%",
      paddingRight: "16px",
    },
    tableContainer: {
      maxHeight: "50vh",
      overflowY: "auto"as "auto",
      margin: "40px 0px",
      width: "100%",
      paddingRight: "16px",
      "&::-webkit-scrollbar":{
        width: "6px",
      },
      "&::-webkit-scrollbar-track":{
        background: "#E9E9E9",
        borderRadius: "5px",
      },
     "&::-webkit-scrollbar-thumb":{
        background: "#BABABA",
        borderRadius: "5px",
        height:"62px",
      },
      "&::-webkit-scrollbar-thumb:hover":{
        background: "#BABABA"
      }
    },
    table: {
      width:"100%"
    },
    name: {
      color: "#fff",
      fontWeight: 600,
      fontFamily: "Manrope",
      fontSize: "16px",
    },
    nameDark:{
      color: "#222222",
      fontWeight: 600,
      fontFamily: "Manrope",
      fontSize: "16px",

    },
    description1: {
      fontWeight: 500,
      fontFamily: "Manrope",
      color: "#6B6B6B",
      fontSize: "10px",     
    },
    startTableCellContent: {
      borderBottom: "none",
      borderTopLeftRadius: 10,
      fontFamily: "Manrope",
      fontSize: "clamp(14px,2vw,18px)",
      fontWeight: 600,
      color: theme.palette.info.main,
      display: "flex",
      padding:"0px !important"
    },
    profileContainer: {
      display: "flex",
      flexDirection: "row" as "row",
      alignItems: "center",
      gap: 4,
    },
    profileImage: {
      height: "44px",
      width: "44px",
      borderRadius: "50px;",
      border: "2px dashed #F0E5FF",
    },
    tableHead: {
      borderRadius: "18px",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
      marginBottom: "12px",
      height: "50px",
    },

    buttonStyle1: {          
      background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      borderRadius: "10px",
      textTransform: "capitalize" as "capitalize",
      color: "#FFF",
      height:'48px',
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
      padding:"16px", 
      boxShadow:"none !important",
      "&.MuiButton-root.Mui-disabled": {
        opacity:0.64
      },         
  },
      buttonStyle: {          
          background: `url(${darkThemeBg}) padding-box fixed ,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
          backgroundClip: "padding-box, border-box",
          backgroundOrigin: "padding-box, border-box",
          border :"1px solid transparent",
          borderRadius: "10px",
          textTransform: "capitalize" as "capitalize",
          color: "#FFF",
          height:'48px',
          fontFamily: "Manrope",
          fontSize: "14px",
          fontStyle: "normal" as 'normal',
          fontWeight: 700,
          backgroundRepeat:"no-repeat",
          backgroundPosition:'center',
          backgroundSize:'cover',
          padding:"16px", 
          boxShadow:"none !important",
          [theme.breakpoints.down('sm')]:{
            backgroundClip: "padding-box, border-box",
            backgroundOrigin: "padding-box, border-box",
            border :"1px solid transparent",
            borderRadius: "10px",
            background: `linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
          }, 
          "&.MuiButton-root.Mui-disabled": {
            opacity:0.64
          },         
      },
      buttonStyleDArk: {       
        fontFamily: "Manrope",
        fontSize: "14px",
        height:'48px',
        fontStyle: "normal" as 'normal',
        fontWeight: 700,
        padding:"16px", 
        boxShadow:"none !important",
        background: `url(${lightThemeBg}) padding-box fixed,linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) border-box`,
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border :"1px solid transparent",
        borderRadius: "10px",
        backgroundPosition:'center',
        backgroundRepeat:"no-repeat",
        backgroundSize:'cover',
        textTransform: "capitalize" as "capitalize",
        color: "black", 
        [theme.breakpoints.down('sm')]:{
          backgroundClip: "padding-box, border-box",
            backgroundOrigin: "padding-box, border-box",
            border :"1px solid transparent",
            borderRadius: "10px",
          background: `linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
        }, 
        "&.MuiButton-root.Mui-disabled": {
          opacity:0.64
        }, 

    },
    buttonStyleDArk1: {       
      fontFamily: "Manrope",
      fontSize: "14px",
      height:'48px',
      padding:"16px", 
      boxShadow:"none !important",
      fontStyle: "normal" as 'normal',
      fontWeight: 700,
      background: `linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)`,
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border :"1px solid transparent",
      borderRadius: "10px",
      textTransform: "capitalize" as "capitalize",
      color: "black", 
      "&.MuiButton-root.Mui-disabled": {
        opacity:0.64
      }, 

  },
    Icons:{
      margin:"0px 8px",
      width: "24px",
      height: "24px",      
    },
      gridContainer: {          
          height: '90vh',
          paddingTop:"3rem",
          justifyContent: 'space-between' as'space-between',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: 
          {justifyContent: 'center' as 'center'}  
      },
      arrowImage: {
         height:'350px',
         width:'350px',
      },
      description: {
          textAlign: 'center' as 'center',
          fontFamily: "Manrope",
          fontSize: "18px",
          fontStyle: "normal" as 'normal',
          fontWeight: 600,
          marginTop:'-20px'
        },
       imageGridItem:{
          display:'flex',
          flexDirection:'column' as 'column',
          justifyContent:'center',
          alignItems:'center',
          [theme.breakpoints.down('xs')]: { display: 'none',paddingLeft:'0px' },
          [theme.breakpoints.down('sm')]: {display: 'none',paddingLeft:'0px' },
          height:'80vh',
          paddingLeft:'80px',
        },
      lightColor:{
        backgroundColor:'#fff'
      },
      darkColor:{
        backgroundColor:'#070707'
      },
      background:{
        backgroundImage: `url(${darkThemeBg})`,
        height: "100vh",
        width: "100%",
        overflow: "auto",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition:'center',
        backgroundAttachment: "scroll",
        [theme.breakpoints.down('sm')]: 
        {backgroundImage: 'none'} 
      },
      lightBackground:{
        backgroundImage: `url(${lightThemeBg})`,
        height: "100vh",
        width: "100%",
        overflow: "auto",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition:'center',
        backgroundAttachment: "scroll",
        [theme.breakpoints.down('sm')]: 
        {backgroundImage: 'none'} 
    },
    formBox:{
      width:'80%',
      display:"flex",
      flexDirection:"column" as 'column',
      rowGap:"20px"
    },
    footer:{
      width:'80%',
      margin:"60px 0px"

    },
    head:{
      width:'80%',
      margin:"30px 0px"
    },
    formContainer:{
      display:'flex',
      flexDirection:'column' as 'column',
      justifyContent:'center',
      alignItems:'flex-start',
      maxWidth:525,
      width:"100%"
    },
   };

  
export const SocialFollowers=withStyles(styles)(Followers) 

// Customizable Area End
