import React from "react";
// Customizable Area Start
import PostCreationController, { Media, PostData, Props } from "./PostCreationController.web";
import { styled, Box, IconButton, Button, Typography, Popover, Radio, Modal, TextField, InputAdornment } from "@material-ui/core";
import { Skeleton } from '@material-ui/lab'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import ReactPlayer from "react-player";
import {
  addPost, leftArrowIcon, lightAddPost, closeIcon, audience,
  checkbox, lightCheckbox, darkCheckBox, closeLightBtn, emotion, attachments,
  darkView, darkRightAngle, searchLight, warningIcon, checkIcon,
  downArrowIcon, liveIcon, emotionLight, attachmentsLight,
} from "./assets";
import Hashtags from "../../hashtags/src/Hashtags.web";
import _ from 'lodash'
import { storyImage } from "../../hashtags/src/assets";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import Gif from './Gif.web'
import ParentPostUi from '../../repost/src/ParentPostUi.web'
import YoutubePreview from ".././../../components/src/Posts/YoutubePreview";
import Location from '../../location/src/Location.web'
import { pdfPlaceholder } from "../../activityfeed/src/assests";

SwiperCore.use([Navigation])

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: 'rgba(0,0,0,0.75)'
    }
  }
});

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: "rgba(255,255,255,0.75)"
    }
  }
});

const audienceList: { id: number, audience: string }[] = [
  { id: 0, audience: 'Everyone' },
  { id: 1, audience: "Followers" },
  { id: 2, audience: 'Paid Subscriber' }
]
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  renderRemoveIcon = (postIndex: number, mediaIndex: number) => {
    return <IconButton data-testid="remove-btn" onClick={() => this.handleRemoveMediaFile(postIndex, mediaIndex)}>
      <img src={closeIcon} style={{ height: 32, width: 32 }} />
    </IconButton>
  }

  renderMedia = (media: Media[], postIndex: number) => {
    const postMedia = _.cloneDeep(media)
    const uploadingMediaIds = this.state.postData[postIndex].uploadingMediaIds
    const hasOneItem = postMedia.length + uploadingMediaIds.length === 1 
    const hasRenderData = postMedia.length + uploadingMediaIds.length > 0
    const slidesPerView = hasOneItem ? 1 : 2
    const boxContainer = hasOneItem ? "box-fullContainer" : "box-container"

    return (
      <>
        {hasRenderData && (
          <div key={postIndex} style={{ position: "relative", width: "100%", marginBottom: '10px' }}>
            <Swiper
              spaceBetween={20}
              slidesPerView={slidesPerView}
              navigation={{
                nextEl: `.swiper-button-next-${postIndex}`,
                prevEl: `.swiper-button-prev-${postIndex}`,
              }}
              allowTouchMove={false}
            >
              {uploadingMediaIds.map(id => (
                <SwiperSlide id={id} key='loading' className="box-container">
                  <Skeleton
                    style={{width: '100%', height: '100%', borderRadius: '12px'}}
                    variant="rect"
                    animation="wave"
                  />
                </SwiperSlide>
              ))}
              {postMedia.map((item) => {
                if (item.contentType.slice(0, 5) === "video") {
                  return <SwiperSlide key={item.id} data-testid="videoPreview" className={boxContainer}>
                    <Box className="closeIcon">
                      {this.renderRemoveIcon(postIndex, item.id)}
                    </Box>
                    <Box>
                      {this.renderVideoPlayer(item.url)}
                    </Box>
                  </SwiperSlide>
                } else if (item.contentType.slice(0, 5) === "image") {
                  return (
                    <SwiperSlide key={item.id} data-testid="imagePreview" className={boxContainer}>
                      <Box className="closeIcon">
                        {this.renderRemoveIcon(postIndex, item.id)}
                      </Box>
                      <Box>
                        <img src={item.url} style={{ height: "100%", width: "100%", borderRadius: 12, objectFit: "cover" }} />
                      </Box>
                    </SwiperSlide>
                  )
                } else {
                  return (
                    <SwiperSlide key={item.id} data-testid="imagePreview" className={boxContainer}>
                      <Box className="closeIcon">
                        {this.renderRemoveIcon(postIndex, item.id)}
                      </Box>
                      <Box>
                        <img src={item.thumbnailUrl ?? pdfPlaceholder} style={{ height: "100%", width: "100%", borderRadius: 12, objectFit: "cover" }} />
                      </Box>
                    </SwiperSlide>
                  )
                }
              })}
            </Swiper>
            <div>
              <div className={`swiper-button-prev swiper-button-prev-${postIndex}`}>
                <img src={darkView} height={12} width={12} />
              </div>
              <div className={`swiper-button-next swiper-button-next-${postIndex}`}>
                <img src={darkRightAngle} height={12} width={12} />
              </div>
            </div>
            {this.state.selectedPostIndex === postIndex && postMedia.length > 0 && (
              <Box
                data-testid="open-tag-modal-button"
                style={{ padding: '5px 10px', border: '1px solid #4E4E4E', display: 'inline-block', borderRadius: '10px', marginTop: '8px', cursor: 'pointer' }}
                onClick={this.handleClickShowMentionPeople}
              >
                <Typography style={{ fontFamily: 'Manrope', fontSize: '12px', fontWeight: 500, color: '#FFF' }}>
                  {this.state.postData[postIndex].mediaMentions.length === 0 ? 'Tag people' : this.generateText(this.state.postData[postIndex].mediaMentions)}
                </Typography>
              </Box>
            )}
          </div>
        )}
      </>
    )
  }

  renderVideoPlayer = (url: string) => {
    return (
      <>
        <ReactPlayer
          url={url}
          controls={true}
          height={"100%"}
          width={"100%"}
          style={{ borderRadius: "12px" }}
        />
      </>
    )
  }

  renderAddPostButton = (index: number) => {
    return (
      <IconButton data-testid="add-post-button" onClick={() => this.handAddPostBtn(index)}>
        <img src={this.props.checked ? addPost : lightAddPost} />
      </IconButton>
    )
  }

  renderLocationButton = () => {
    return (
      <Location 
        id="" 
        checked={!!this.props.checked} 
        navigation={this.props.navigation} 
        onSelectPlace={this.handleSelectLocation}
      />
    )
  }

  renderEmotionButton = () => {
    const { emojiAnchorEl } = this.state;
    const open = Boolean(emojiAnchorEl);
    const id = open ? 'emoji-popover' : undefined;

    return (
      <Box>
        <IconButton data-testid="emoji-picker-button" onClick={this.handleClickEmoji}>
          <img src={this.props.checked ? emotionLight : emotion} />
        </IconButton>
        <EmojiPopover
          id={id}
          open={open}
          anchorEl={emojiAnchorEl}
          onClose={this.handleCloseEmoji}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Picker 
            data-testid="emoji-picker" 
            theme="dark" 
            onSelect={this.handleSelect} 
            autoFocus
          />
        </EmojiPopover>
      </Box>
    )
  }

  renderUploadingButton = (index: number) => {
    return (
      <IconButton 
        role={undefined} 
        tabIndex={-1} 
        component="label" 
        style={{cursor: this.state.processingHlsVideoId ? 'not-allowed' : 'pointer'}}
      >
        <img src={this.props.checked ? attachmentsLight : attachments} />
        <input
          type="file"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeFile(event, index)}
          hidden
          accept=".png,.jpeg,.webp,.jpg,.mp4,.pdf"
          style={{
            display: "none",
          }}
          data-testid="filePicker"
          disabled={!!this.state.processingHlsVideoId}
        />
      </IconButton>
    )
  }

  renderAudienceList = () => {
    const popper = Boolean(this.state.anchorEl)
    const id = popper ? 'simple-popper' : undefined;
    const width = this.state.showMultiPostsModal ? 540 : this.state.postCreationWidth + 1
    return (
      <Popover
        id={id}
        open={popper}
        data-testid="popover"
        anchorEl={this.state.anchorEl}
        onClose={this.handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box>
          {audienceList.map((item) => {
            const activeIcon = this.state.selectedAudience === item.id
            return (
              <AudienceContainer style={{width: width}} key={item.id} className="audience_container">
                <Box>
                  <Typography className={activeIcon ? "active_item_list_name" : "item_list_name"}>{item.audience}</Typography>
                </Box>
                <Radio
                  checked={this.state.selectedAudience === item.id}
                  name="radio-button"
                  data-testid="RadioOptions"
                  value={item.id}
                  onChange={this.handleCheckboxChange}
                  icon={<img src={this.props.checked ? lightCheckbox : darkCheckBox} alt="Avatar" />}
                  checkedIcon={<img src={checkbox} alt="Avatar" />}
                />
              </AudienceContainer>
            )
          })}
        </Box>
      </Popover>
    )
  }

  renderPostItem = (postData: PostData, postIndex: number, isNotInModal: boolean) => {
    const isPostHasData = this.checkPostHasData(postData)
    const isPostHasMedia = postData.media.length > 0
    return (
      <PostItemWrapper key={isNotInModal ? 'IN-MAIN-SCREEN' : postIndex}>
        <Box className="input-editor" style={{minHeight: isPostHasMedia ? '40px' : '80px'}}>
          <Hashtags
            data-testid="status_text_field"
            checked={!!this.props.checked}
            navigation={this.props.navigation}
            id=''
            classes={{}}
            value={postData.body}
            onChangeValue={(text: { target: { value: string } }) => this.handleChangeValue(text.target.value, postIndex)}
            onFocus={() => this.setState({ selectedPostIndex: postIndex })}
            onClosePostBtn={() => this.handleClosePostBtn(postIndex)}
            showClosePostBtn={!isPostHasData && this.state.postData.length > 1}
            placeholder='What’s happening ?'
            loggedUserAvatar={this.props.loggedUserAvatar}
          />
        </Box>
        {postData.media.length === 0 && (
          <YoutubePreview postContent={postData.body} checked={!!this.props.checked} />
        )}
        <Box>
          {this.renderMedia(postData.media, postIndex)}
        </Box>
        {this.props.postData && (
          <Box style={{paddingLeft: '40px'}}>
            <ParentPostUi postData={this.props.postData} checked={!!this.props.checked} />
          </Box>
        )}
        {((this.state.selectedPostIndex === postIndex) || isNotInModal) && (
          <>
            <Box data-testid="choose_Audience" className="selected_audience_wrapper" onClick={this.handleAudienceClick}>
              <img src={audience} alt="audience" width={16} height={16} />
              <Typography className="selected_audience_text">{this.chooseAudienceText()}</Typography>
              <img src={Boolean(this.state.anchorEl) ? downArrowIcon : leftArrowIcon} alt="audience" style={{marginLeft: 'auto'}} />
            </Box>
            <div ref={this.postCreationRef} className="attached-action-items">
              <Box className="attached-items">
                {this.renderUploadingButton(postIndex)}
                {this.renderLocationButton()}
                <Gif onSelectGif={this.uploadGifFile} postIndex={postIndex} checked={this.props.checked}/>
                {this.renderEmotionButton()}
                <IconButton onClick={this.handleRedirection}>
                  <img src={liveIcon} style={{cursor:"pointer"}}/>
                </IconButton>
                {isPostHasData && !this.props.postData && this.renderAddPostButton(postIndex)}
              </Box>
              <Box className="post-Button">
                <Button
                  data-testid={"post-btn"}
                  disabled={!this.checkMultiPostHasData || this.hasFileUpLoading}
                  className="profileButton"
                  onClick={this.submitPosts}
                >
                  {this.state.postData.length === 1 ? 'Post' : 'Post all'}
                </Button>
              </Box>
            </div>
          </>
        )}
      </PostItemWrapper>
    )
  }

  renderModal = () => {
    return (
      <ModalWrapper open={this.state.showMultiPostsModal} closeAfterTransition>
        <Box className="modalContainer">
          <Box className="closeBtnWrapper">
            <IconButton onClick={this.handleClosePostModal}>
              <img src={closeLightBtn} width={20} height={20} />
            </IconButton>
          </Box>
          <Box className="listPostContainer">
            {this.state.postData.map((post, index) => {
              return this.renderPostItem(post, index, false)
            })}
          </Box>
        </Box>
      </ModalWrapper>
    )
  }

  renderTagPeople = () => {
    const mediaMentionList = this.state.temporaryMentionPeopleList
    const mediaMentionsCurrentPost = this.state.postData[this.state.selectedPostIndex]?.mediaMentions
    const isDisableDoneBtn = this.comparePeopleMentioning(mediaMentionList, mediaMentionsCurrentPost)

    return (
      <ModalTagPeople
        data-testid="ModalTagPeople"
        open={this.state.showMentionPeopleMedia}
        onClose={this.handleClickCloseMentionModal}
      >
        <Box className="modalWrapper">
          <Box className="modalHeaderWrapper">
            <IconButton onClick={this.handleClickCloseMentionModal}>
              <img src={closeLightBtn} width={20} height={20} />
            </IconButton>
            <Typography className="tagPeopleTitle">Tag People</Typography>
            <Button
              className="doneTagButton"
              onClick={this.handleCompleteSelectMentionPeople}
              disabled={isDisableDoneBtn}
            >
              Done
            </Button>
          </Box>
          <TextField
            data-testid="search-mention-text-field"
            fullWidth
            autoComplete="off"
            placeholder="Search people"
            value={this.state.searchMention}
            onChange={this.handleChangeSearchPeople}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchLight} width={16} height={16} />
                </InputAdornment>
              ),
            }}
            className="tagPeopleInput"
            autoFocus
            inputRef={this.tagInputRef}
          />
          <Box className="mentionedPeopleContainer">
            {mediaMentionList.map(item => (
              <Box
                key={item.id}
                data-testid="selectedMentionPerson"
                className="mentionedPeopleItem"
                onClick={() => this.handleRemoveSelectedMention(item)}
              >
                <img src={item.profilePhoto || storyImage} className="mentionedPeopleImg" />
                <Typography className="mentionedPeopleName">{item.fullName}</Typography>
                <img src={closeLightBtn} width={18} height={18} />
              </Box>
            ))}
          </Box>
          <Box className="mentionPersonContainer">
            {mediaMentionList.length >= 10 && this.state.mentionPeople.length > 0 && (
              <Box className="warningNumberTagged">
                <img src={warningIcon} width={18} height={18} />
                <Typography className="warningNumberTaggedText">Only 10 people can be tagged.</Typography>
              </Box>
            )}
            {this.state.mentionPeople.map(person => {
              return (
                <Box
                  key={person.id}
                  data-testid="mentionPersonItem"
                  className="mentionPersonWrapper"
                  onClick={() => {
                    this.handleClickSuggestMention(person)
                    this.tagInputRef.current && this.tagInputRef.current.focus()
                  }}
                  style={{
                    cursor: mediaMentionList.length >= 10 ? 'none' : 'pointer',
                    opacity: mediaMentionList.length >= 10 ? '0.5' : '1',
                    pointerEvents: mediaMentionList.length >= 10 ? 'none' : 'auto',
                  }}
                >
                  <img src={person.profilePhoto || storyImage} className="mentionPersonImg" />
                  <Box>
                    <Typography className="mentionPersonFullName">{person.fullName}</Typography>
                    <Typography className="mentionPersonUserName">{person.userName}</Typography>
                  </Box>
                  {person.isSelected && <img src={checkIcon} className="mentionPersonCheckIcon" />}
                </Box>
              )
            })}
          </Box>
        </Box>
      </ModalTagPeople>
    )
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.props.checked ? lightTheme : darkTheme}>
        <Box style={{ padding: '20px 0' }}>
          {this.renderPostItem(this.dataForSinglePost, 0, true)}
        </Box>
        {this.renderAudienceList()}
        {this.renderModal()}
        {this.renderTagPeople()}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const EmojiPopover = styled(Popover)({
  '& .emoji-mart-search': {
    '& input': {
      borderColor: '#ae65c5',
    },
  },
  
  '& .emoji-mart-anchor': {
    cursor: 'pointer',
  },

  '& .emoji-mart-emoji': {
    '& span': {
      cursor: 'pointer !important',
    }
  },
})

const AudienceContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',

  "& .item_list_name": {
    padding: '12px 20px',
    fontFamily: "Manrope",
    fontSize: 14,
    fontWeight: 500,
  },

  "& .active_item_list_name": {
    padding: '12px 20px',
    fontFamily: "Manrope",
    fontSize: 14,
    fontWeight: 700,
  },
})

const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  backgroundColor: 'rgba(151, 151, 151, 0.6)',
  overflow: 'visible',

  '& .modalContainer': {
    width: '600px',
    backgroundColor: '#191520',
    borderRadius: '12px',
    marginTop: '80px',
    overflow: 'visible',
  },

  '& .closeBtnWrapper': {
    marginLeft: '8px'
  },

  '& .listPostContainer': {
    flex: 1,
    maxHeight: '80vh',
    overflowY: 'scroll',
  }
})

const ModalTagPeople = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  backgroundColor: 'rgba(151, 151, 151, 0.6)',

  '& .modalWrapper': {
    width: '600px',
    backgroundColor: '#191520',
    borderRadius: '12px',
    marginTop: '80px',
    minHeight: '460px',
    paddingBottom: '12px',
  },

  '& .modalHeaderWrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px 12px 4px'
  },

  '& .tagPeopleTitle': {
    fontFamily: 'Manrope',
    fontWeight: '800',
    color: '#FFF',
    fontSize: '18px',
  },

  "& .doneTagButton": {
    textTransform: "capitalize",
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    color: "#FFFFFF",
    cursor: "pointer",
    fontWeight: 600,
    fontFamily: "Manrope",
    fontSize: "clamp(12px,2vw,14px)",
    "&:hover": {
      background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    },
    "&.Mui-disabled": {
      background: "linear-gradient(-45deg, #8f86ed, #d35bf8)"
    },
    padding: '6px 8px',
    borderRadius: "8px",
  },
  "& .tagPeopleInput": {
    '& [class*="MuiInputAdornment-positionStart"]': {
      marginLeft: '16px',
    },
    '& [class*="MuiInput-underline"]': {
      '&:before': {
        borderWidth: "0",
      },
      '&:after': {
        borderWidth: "0",
      },
      '&:hover': {
        borderWidth: "0",
      }
    },
    '& [class*="MuiInputBase-input"]': {
      paddingLeft: '12px',
      fontSize: '14px',
      fontFamily: 'Manrope',
      fontWeight: 500,
    },
  },

  "& .mentionedPeopleContainer": {
    display: 'flex',
    padding: '0 16px',
    gap: '12px',
    marginTop: '12px',
    flexWrap: 'wrap',
  },

  "& .mentionedPeopleItem": {
    display: 'flex',
    border: '1px solid #fff',
    borderRadius: '12px',
    alignItems: 'center',
    padding: '4px 8px 4px 4px',
    gap: '8px',
    cursor: 'pointer',
  },

  "& .mentionedPeopleImg": {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
  },

  "& .mentionedPeopleName": {
    fontFamily: 'Manrope',
    fontWeight: '600',
    fontSize: '16px',
    color: '#FFF',
  },

  "& .mentionPersonContainer": {
    margin: '12px 0 0',
    borderTop: '1px solid #fff',
    height: '50vh',
    overflowY: 'scroll',
  },

  "& .warningNumberTagged": {
    margin: '16px 16px 8px',
    padding: '12px 16px',
    borderRadius: '12px',
    backgroundColor: '#F87171',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },

  '& .warningNumberTaggedText': {
    fontFamily: 'Manrope',
    fontWeight: '500',
    fontSize: '14px',
    color: '#FFF'
  },

  "& .mentionPersonWrapper": {
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',

    "&:hover": {
      backgroundColor: 'rgba(151, 151, 151, 0.4)',
    }
  },

  "& .mentionPersonImg": {
    width: '44px',
    height: '44px',
    borderRadius: '50%'
  },

  "& .mentionPersonFullName": {
    fontFamily: 'Manrope',
    fontWeight: '600',
    fontSize: '16px',
    color: '#FFF'
  },

  "& .mentionPersonUserName": {
    fontFamily: 'Manrope',
    fontWeight: '500',
    fontSize: '10px',
    color: '#FFF'
  },

  "& .mentionPersonCheckIcon": {
    width: '20px',
    height: '20px',
    marginLeft: 'auto',
  },
}))

const PostItemWrapper = styled(Box)({
  padding: "0 24px 0 32px",
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
  borderBottom: "1px solid #222222",

  "& .input-editor": {
    display: "flex",
    width: "100%",
  },

  "& .box-container": {
    height: 250,
    width: "100%",
    borderRadius: 12,
    position: 'relative',
  },

  "& .box-fullContainer": {
    maxHeight: 360,
    position: 'relative',
    height: "100%",
    width: "100%",
    borderRadius: 12,
    overflowY: 'scroll',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
  },

  '& .box-fullContainer::-webkit-scrollbar': {
    display: 'none'
  },

  "& .custom-navigation": {
    display: "flex",
    position: "absolute",
    width: "100%",
    top: "40%",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 22,
    marginTop: "10px",
  },

  '& .swiper-button-prev, .swiper-button-next': {
    position: 'absolute',
    top: '50%',
    width: '32px',
    height: '32px',
    marginTop: '-16pxpx',
    zIndex: 10,
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '50%',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    '&::after': {
      content: '""',
    }
  },

  '& .swiper-button-disabled': {
    display: 'none'
  },

  "& .choose_audience_container": {
    height: "24px",
    borderRadius: '10px',
    display: "flex",
    width: "163px",
    cursor: "pointer",
    alignItems: 'center',
    border: "1px solid #222222",
    padding: "0px 10px",
    gap: "5px",
  },

  "& .choose_audience_text": {
    fontFamily: "Manrope",
    fontSize: 12,
    fontWeight: 500,
    background: 'linear-gradient(#3858E3, #BA05F7)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  },
  '& .selected_audience_wrapper': {
    display: 'flex', 
    alignItems: 'center', 
    gap: '10px', 
    borderBottom: '1px solid #222', 
    borderTop: '1px solid #222', 
    padding: '12 14px 12px 20px',
    cursor: "pointer",
  },
  "& .selected_audience_text": {
    fontFamily: "Manrope",
    fontSize: 14,
    fontWeight: 700,
    background: 'linear-gradient(#3858E3, #BA05F7)',
    color: 'transparent',
    WebkitBackgroundClip: 'text',
  },
  "& .slider_icon": {
    backgroundColor: "#222222",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    width: "50px",
    borderRadius: "50px",
  },

  "& .dark_slider_icon": {
    backgroundColor: "#ffff",
    color: "#222222",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    width: "50px",
    borderRadius: "50px",
  },

  "& .closeIcon": {
    position: "absolute",
    right: -4,
    top: -4,
    zIndex: 2,
  },

  "& .avatarImage": {
    height: "32px",
    width: "32px",
    barderRadius: '100px',
    barder: "1px solid #F0E5FF"
  },

  "& .attached-action-items": {
    display: "flex",
    width: "100%",
    justifyContent: 'space-between',
    alignItems: "center",
    minHeight: "54px",
  },

  "& .attached-items": {
    display: 'flex'
  },

  "& .profileButton": {
    textTransform: "capitalize",
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    color: "#FFFFFF",
    cursor: "pointer",
    fontWeight: 600,
    fontFamily: "Manrope",
    fontSize: "clamp(12px,2vw,14px)",
    "&:hover": {
      background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    },
    "&.Mui-disabled": {
      background: "linear-gradient(-45deg, #8f86ed, #d35bf8)"
    },
    borderRadius: "5px",
    height: "35px",
    width: "73px",
  },
})
// Customizable Area End
