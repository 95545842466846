import { CometChatCalls } from "@cometchat/calls-sdk-javascript";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { Box, IconButton, styled, Typography } from "@material-ui/core";
import { EMITTER_EVENT_VARIABLES, emitterEvents, getCredCometChat } from "../../../components/src/utils";
import React from "react";
import { CloseOutlined } from "@material-ui/icons";
import ImageRenderer from "../../../components/src/ImageRenderer.web";
import { acceptCallIcon, rejectCallIcon } from "../../../blocks/call-recording/src/assets";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EventSubscription } from "fbemitter";
import { toast } from "react-toastify";

interface Props extends RouteComponentProps {
}

interface S {
    isOpenModal: boolean;
    sessionIDCall: string;
}

class NotificationAudioCall extends BlockComponent<Props, S, {}> {
    subscriptionResetCall: EventSubscription | null = null;
    subscriptionCancelCall: EventSubscription | null = null;
    subscriptionAcceptCall: EventSubscription | null = null;
    subscriptionEndCall: EventSubscription | null = null;

    constructor(props: Props) {
        super(props)

        this.state = {
            isOpenModal: false,
            sessionIDCall: "",
        }
    }

    async componentDidMount(): Promise<void> {

        this.subscriptionResetCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.CLOSE_MODAL_CALL, (bool: Boolean) => {
            if (!bool) return;
            this.handleResetCall();
        })

        this.subscriptionAcceptCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.ACCEPT_CALL, (sessionID: string) => {
            this.setState({ sessionIDCall: sessionID, isOpenModal: false })
        })

        this.subscriptionCancelCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.CANCEL_CALL, (call: CometChat.Call) => {
            if (!call.getSessionId()) return;
            this.handleResetCall();
        })

        this.subscriptionEndCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.END_CALL_SESSION, () => {
            this.handleResetCall();
        })

        const callAppSetting = new CometChatCalls.CallAppSettingsBuilder()
            .setAppId(getCredCometChat().APP_ID)
            .setRegion(getCredCometChat().REGION)
            .build();

        CometChatCalls.init(callAppSetting).then(
            () => {
                console.log("CometChatCalls initialization completed successfully");
                CometChat.addCallListener(
                    "CALL_LISTENER_ID",
                    new CometChat.CallListener({
                        onIncomingCallReceived: async (call: CometChat.Call) => {
                            if (this.state.sessionIDCall) {
                                await this.handleRejectCall(call.getSessionId(), CometChat.CALL_STATUS.BUSY);
                                return;
                            }
                            console.log("Incoming call:", call, this.state.sessionIDCall);
                            this.setState({ sessionIDCall: call.getSessionId(), isOpenModal: true }, () => {
                                emitterEvents.emit(EMITTER_EVENT_VARIABLES.INCOMING_CALL, call)
                            });
                        },
                        onOutgoingCallAccepted: async (call: CometChat.Call) => {
                            console.log("Outgoing call accepted:", call);
                            emitterEvents.emit(EMITTER_EVENT_VARIABLES.START_CALL_SESSION, call)
                            this.setState({ isOpenModal: false, sessionIDCall: call.getSessionId() })
                        },
                        onOutgoingCallRejected: (call: CometChat.Call) => {
                            console.log("Outgoing call rejected:", call);
                            const actionCall = call.getAction();
                            if (actionCall === CometChat.CALL_STATUS.BUSY) {
                                toast.error("The user is currently busy and cannot connect the call.")
                            } else {
                                toast.warn("The user is not available for your call")
                            }
                            emitterEvents.emit(EMITTER_EVENT_VARIABLES.REJECT_CALL, call)
                        },
                        onIncomingCallCancelled: (call: CometChat.Call) => {
                            console.log("Incoming call calcelled:", call);
                            emitterEvents.emit(EMITTER_EVENT_VARIABLES.CANCEL_CALL, call)
                        },
                        onCallEndedMessageReceived: (call: CometChat.Call) => {
                            console.log("CallEnded Message:", call);
                            this.setState({ sessionIDCall: "" })
                            CometChat.clearActiveCall();
                            CometChatCalls.endSession();
                        },
                    }),
                );
            },
            (error) => {
                console.log("CometChatCalls initialization failed with error:", error);
            }
        );
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        const { sessionIDCall } = this.state;
        const { location } = this.props;
        if (prevProps.location !== location) {
            if (location.pathname.toLowerCase() === "/callrecording") {
                this.setState({ isOpenModal: false });
                return;
            }
            if (this.state.sessionIDCall)
                this.setState({ isOpenModal: true });
        }
        if (prevState.sessionIDCall !== sessionIDCall && sessionIDCall) {
            if (location.pathname.toLowerCase() === "/callrecording") {
                this.setState({ isOpenModal: false });
                return;
            }
            this.setState({ isOpenModal: true })
        }
    }

    async componentWillUnmount(): Promise<void> {
        if (this.subscriptionResetCall) {
            this.subscriptionResetCall.remove()
        }

        if (this.subscriptionCancelCall) {
            this.subscriptionCancelCall.remove()
        }
        CometChat.removeCallListener("CALL_LISTENER_ID");
    }

    handleNavigateCall = async () => {
        const navigate = (url: string, payload: any) => {
            this.props.history.push(url)
        }
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "CallRecording");
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            {
                ...this.props,
                navigation: { navigate }
            }
        );

        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );

        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
            isAcceptCall: true,
            sessionIDCall: this.state.sessionIDCall
        });

        msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

        runEngine.sendMessage("NotificationAudioCall", msg);
    }

    handleRejectCall = async (sessionIdCall?: string, statusCall?: string) => {
        try {
            const status = statusCall ?? CometChat.CALL_STATUS.REJECTED;
            const call = await CometChat.rejectCall(sessionIdCall ?? this.state.sessionIDCall, status)
            console.log("Call rejected successfully:", call);
            if (call.getSessionId() !== this.state.sessionIDCall) return;
            this.handleResetCall();
        } catch (error) {
            console.error("Reject call in notification failled with error: ", error)
        }
    }

    handleResetCall = () => {
        this.setState({ sessionIDCall: "", isOpenModal: false })
    }

    render() {
        return this.state.isOpenModal && (
            <NotificationAudioCallStyled>
                <Box className="header">
                    <IconButton className="exitBtn">
                        <CloseOutlined style={{ color: "white" }} />
                    </IconButton>
                </Box>
                <Box className="container">
                    <Typography className="textContent">
                        There is an Incoming Call
                    </Typography>
                    <Box className="list_button">
                        <IconButton onClick={this.handleNavigateCall}>
                            <ImageRenderer className="callBtn" src={acceptCallIcon} width={24} height={24} />
                            <Typography className="text">
                                Accept
                            </Typography>
                        </IconButton>
                        <IconButton onClick={() => this.handleRejectCall()}>
                            <ImageRenderer className="callBtn" src={rejectCallIcon} width={24} height={24} />
                            <Typography className="text">
                                Reject
                            </Typography>
                        </IconButton>
                    </Box>
                </Box>
            </NotificationAudioCallStyled>
        )
    }
}

const NotificationAudioCallStyled = styled(Box)(() => ({
    border: "1px solid #222222",
    borderRadius: 8,
    padding: "0 20px 12px",
    paddingRight: 0,
    position: "absolute",
    zIndex: 99999999999999,
    background: "#222222",
    right: 20,
    top: 20,
    "& .text": {
        fontFamily: "Manrope",
        color: "#FFFFFF",
        fontWeight: 500,
        fontSize: 12,
    },
    "& .header": {
        display: "flex",
        flexDirection: "row-reverse",
        position: "relative",
    },
    "& .container": {
        paddingRight: "20px",
        "& .textContent": {
            fontFamily: "Manrope",
            fontWeight: 500,
            fontSize: 14,
            color: "#FFFFFF"
        },
        "& .list_button": {
            display: "flex",
            justifyContent: "space-around",
            "& .callBtn": {
                marginRight: 4
            }
        }
    }
}))

export default withRouter(NotificationAudioCall);