import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import { Conversation } from "./MessageItemController.web";
import _ from 'lodash'
import { toast } from "react-toastify";
import { emitterEvents } from "../../../components/src/utils";
import { EventSubscription } from 'fbemitter'
import { fetchConversations, getListUserFriendComet, sendMediaMessage, sendTextMessage, updateConversationsWhenHasNewMessage } from "../../../components/src/CometChat";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
type TTabTypeChat = "All" | "Group"
type TGroupTabs = "groups" | "subscribed_group"

export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?: boolean,
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean
  activeIndex: "All" | "Group",
  conversationList: Conversation[];
  currentGroupTab: TGroupTabs;
  selectedConversation: CometChat.User | CometChat.Group | null;
  cometChatUid: string
  searchKeyword: string
  searchResults: Conversation[]
  loggedCometChatUser: CometChat.User | null
  showCreateGroupChat: boolean;
  refreshMessageList: boolean;
  profileUidNavigation: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  subscription: EventSubscription | null = null;
  sendMessageCountId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      activeIndex: "All",
      conversationList: [],
      currentGroupTab: "groups",
      selectedConversation: null,
      cometChatUid: '',
      searchKeyword: '',
      searchResults: [],
      loggedCometChatUser: null,
      showCreateGroupChat: false,
      refreshMessageList: false,
      profileUidNavigation: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.debouncedSearchConversation = _.debounce(this.searchConversation, 300)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const cometChatToken: string = localStorage.getItem("cometChatToken") || ''
    if (cometChatToken === 'null' || !cometChatToken) {
      toast.error('This account does not exist in cometchat!')
      return
    }

    const cometChatUid = cometChatToken.split('_')[0]
    this.setState({ cometChatUid })
    const initialCometChat = CometChat.isInitialized()
    if (!initialCometChat) {
      this.subscription = emitterEvents.addListener('initialCometChat', (isDone: boolean) => {
        if (isDone) {
          this.initCometChat(cometChatUid)
        }
      })
      return
    }
    this.initCometChat(cometChatUid)
  }

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const { currentGroupTab } = this.state;
    if (prevState.currentGroupTab !== currentGroupTab) {
      if (currentGroupTab === "subscribed_group") {
        this.setState({ conversationList: [], isLoading: false });
        return;
      }
      this.getConversations();
    }
  }

  async componentWillUnmount() {
    CometChatUIKit.logout().then(() => {
      console.log("Logout completed successfully");
    }).catch((error) => {
      console.log("Logout failed with exception:", error);
    });

    CometChat.removeMessageListener('UNIQUE_LISTENER_ID')
    CometChat.removeMessageListener("GROUP_LISTENER_ID")

    if (this.subscription) {
      this.subscription.remove()
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const passingPramData = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({profileUidNavigation: passingPramData.profileUid})
    }
  }

  initCometChat = async (cometChatUid: string) => {
    this.setState({ isLoading: true })
    CometChatUIKit.getLoggedinUser().then((user: any) => {
      if (!user) {
        CometChatUIKit.login(cometChatUid).then((user: CometChat.User) => {
          this.startCometChat(user)
        }).catch((error) => {
          console.log('Login failed with error', error);
        });
        return;
      }
      this.startCometChat(user)
    }).catch((error) => {
      console.log('Get logged user failed with error', error)
    });
  }

  startCometChat = (user: CometChat.User) => {
    this.setState({ loggedCometChatUser: user })
    this.getConversations();

    this.handleSelectConversation({
      id: this.state.profileUidNavigation,
      type: "USER",
      name: "test",
      avatar: "",
      lastMessage: "",
      lastMessageTimestamp: "",
      unreadMessageCount: 0
    })
    CometChat.addMessageListener(
      "UNIQUE_LISTENER_ID",
      new CometChat.MessageListener({
        onTextMessageReceived: (message: CometChat.TextMessage) => {
          this.updateChatWhenHasNewMessage(message);
          if (message.getParentMessageId()) this.handleRefreshMessageList();
        },
        onMediaMessageReceived: (message: CometChat.TextMessage) => {
          this.updateChatWhenHasNewMessage(message);
          if (message.getParentMessageId()) this.handleRefreshMessageList();
        },
        onMessageDeleted: () => {
          this.getConversations(this.state.activeIndex);
          this.handleRefreshMessageList();
        }
      })
    );
    CometChat.addGroupListener(
      "GROUP_LISTENER_ID",
      new CometChat.GroupListener({
        onMemberAddedToGroup: () => {
          this.getConversations();
        },
      })
    );
  }

  getConversations = async (typeChat?: TTabTypeChat) => {
    let conversations = await fetchConversations();

    if (!conversations || !conversations?.length) {
      this.setState({ isLoading: false })
      return;
    }

    // Filter conversation by type
    if (typeChat === "Group")
      conversations = conversations.filter(conversation => conversation.type.toLowerCase() === "group");

    this.setState({
      conversationList: conversations,
      isLoading: false
    });
  };

  updateChatWhenHasNewMessage = (newMessage: CometChat.TextMessage) => {
    const updateConversations = updateConversationsWhenHasNewMessage(
      newMessage,
      this.state.conversationList
    )

    this.setState({ conversationList: updateConversations })
  };
  sendMessage = async (
    messageText: string,
    expiryTime: number,
    parentMessageIdReply?: number | null
  ) => {
    const { selectedConversation, conversationList } = this.state;
    const updatedConversations = await sendTextMessage(
      messageText,
      selectedConversation,
      conversationList,
      expiryTime,
      parentMessageIdReply
    );
    if (selectedConversation instanceof CometChat.User) {
      const uid = selectedConversation.getUid();
      await this.sendCountMessage(uid);
    }

    if (updatedConversations?.length) {
      this.setState({ conversationList: updatedConversations });
    }
    if (parentMessageIdReply) this.handleRefreshMessageList();
  };

  sendMediaMessage = async (
    file: File[],
    expiryTime: number,
    parentMessageIdReply?: number | null
  ) => {
    const { selectedConversation, conversationList } = this.state;
    const updatedConversations = await sendMediaMessage(
      file,
      selectedConversation,
      conversationList,
      expiryTime,
      parentMessageIdReply
    );
    if (updatedConversations?.length) {
      this.setState({ conversationList: updatedConversations });
    }
    if (parentMessageIdReply) this.handleRefreshMessageList();
  };

  sendCountMessage = async (receiverId: string) => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendMessageCountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.messageCountApiEndPoint + "/" + `${receiverId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  debouncedSearchConversation: (keyword: string) => void

  searchConversation = async (keyword: string) => {
    try {
      let searchKeyword: string = keyword;
      const userListSearch = await getListUserFriendComet({ searchKeyword });
      const formattedUsers = this.formatSearchFriendResult(userListSearch);
      const conversationListSearch = this.state.conversationList.filter(conversation => conversation.name.toLowerCase().includes(searchKeyword.trim().toLowerCase()));
      const combinedList = [...conversationListSearch, ...formattedUsers];
      const uniqueList = _.uniqBy(combinedList, 'id');
      this.setState({ searchResults: uniqueList });
    } catch (err) {
      console.error("User list fetching failed with error ", err)
    }
  }

  formatSearchFriendResult = (userList: CometChat.User[]) => {
    const formattedUsers: Conversation[] = userList.map((user: CometChat.User) => ({
      id: user.getUid().toString(),
      type: 'USER',
      name: user.getName(),
      avatar: user.getAvatar(),
      lastMessage: '',
      lastMessageTimestamp: '',
      unreadMessageCount: 0,
    }));
    return formattedUsers;
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKeyword = event.target.value;
    this.setState({ searchKeyword });
    this.debouncedSearchConversation(searchKeyword.trim());
  };

  handleSelectConversation = (conversation: Conversation) => {
    this.setState({ showCreateGroupChat: false })
    if (conversation.type === 'USER') {
      CometChat.getUser(conversation.id).then((user) => {
        this.setState({
          selectedConversation: user,
          searchKeyword: '',
          searchResults: [],
        })
      }).catch((error) => {
        console.log("Get user failed with error:", error);
      })
    } else {
      CometChat.getGroup(conversation.id).then((group) => {
        this.setState({
          selectedConversation: group,
          searchKeyword: '',
          searchResults: [],
        })
      }).catch((error) => {
        console.log("Get group failed with error:", error);
      })
    }
  };

  resetUnreadMessageConversation = (conversation: Conversation) => {
    conversation["unreadMessageCount"] = 0;
    return conversation;
  }

  handleCloseSelectedConversation = () => {
    this.getConversations(this.state.activeIndex);
    this.setState({ selectedConversation: null });
  }

  handleTabsChange = (event: ChangeEvent<{}>, newValue: TTabTypeChat) => {
    this.setState({ activeIndex: newValue, isLoading: true });
    this.getConversations(newValue);
  }

  handleShowCreateGroupChat = () => {
    this.setState({ showCreateGroupChat: true, selectedConversation: null })
  }

  handleCloseCreateGroupChat = () => {
    this.getConversations(this.state.activeIndex);
    this.setState({ showCreateGroupChat: false })
  }

  handleDoneCreateGroupChat = (newConversation: any) => {
    if (!newConversation.members) {
      this.setState({ selectedConversation: newConversation });
      return;
    }
    const { group } = newConversation;
    this.setState({ selectedConversation: group });
  }

  handleRefreshMessageList = () => {
    this.setState({ refreshMessageList: !this.state.refreshMessageList })
    this.getConversations(this.state.activeIndex);
  }

  handleRefreshConversations = () => {
    this.getConversations(this.state.activeIndex);
  }

  handleSwitchGroupTab = async (groupTab: TGroupTabs) => {
    this.setState({ isLoading: true, currentGroupTab: groupTab })
  }

  // Customizable Area End
}
