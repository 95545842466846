import React from "react";
// Customizable Area Start
import { Box, Button, Switch ,Modal ,Fade , Backdrop } from '@material-ui/core'
import { Theme, createTheme, withStyles } from "@material-ui/core/styles";
import  {backArrowDark,backArrowWhite} from "./assets"
import Loader from "../../../components/src/Loader.web";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    background: {
      default: "#000",
      paper: "#000",
    },
    text: {
      primary: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#000",
      contrastText: "#000",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "#000",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const inputStyles={  
     
  darkBackdrop:{
     backgroundColor:'#0E0E0E',
     opacity:0.8
  },
   fade:{
      border:'1px solid #5B5B5B',
      borderRadius:'10px',
      display: 'flex'
  },
  lightBackdrop:{
    backgroundColor:'#FFFFFF',
    opacity:0.9
  },
  logoutButtonContainer: {
      display: "flex",
      gap: "12px",
  },
  logoutCancelButton: {
      width: "160px",
      height: "48px",
      padding: "18px 16px 18px 16px",
      gap: "10px",
      borderRadius: "10px",
      border: "1px solid"
  },
  logoutYesButton:{
      width: "160px",
      height: "48px",
      padding: "18px 16px 18px 16px",
      gap: "10px",
      borderRadius: "10px",
      background: "rgba(255, 77, 103, 1)"
  },
  iconButton:{
      color: "#6B6B6B",
  }
}
// Customizable Area End

import Discoverysettings3Controller, {
  Props,
  configJSON,
} from "./Discoverysettings3Controller";

export default class Discoverysettings3 extends Discoverysettings3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderRemoveAllAccountsModal = () => {
    const theme  = this.props.checked ? lightTheme : darkTheme
    const style = audienceStyles(theme);
    return (
      <Modal           
        className={`logoutModal removeAccountModal dark-${!this.props.checked}`}
        open={this.state.showRemoveContactsConfirmation}
        closeAfterTransition
        data-testid="modal"
        BackdropComponent={Backdrop}
        BackdropProps={{ style: this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop }}
      >
      <Fade in={this.state.showRemoveContactsConfirmation} style={inputStyles.fade}>
        <div style={style.removeAllAccountsModalContainer} className={`dark-${!this.props.checked} removeAllAccountsModalContainer`}>
          <h6 className="arrowFontFamily font18 m0">{configJSON.RemoveAllContactsPopup}</h6>
          <p className="arrowFontFamily font16 m0">{configJSON.RemoveAllContactsPopupDescription}</p>
          <div style={inputStyles.logoutButtonContainer}>
            <Button className="cancelButton" data-testid="cancelBtn" onClick={this.onCloseRemoveAccountModal} style={inputStyles.logoutCancelButton} >{configJSON.Cancel}</Button>
            <Button data-test-id="logout-confirm-modal" data-testid="confirmBtn" className="confirmButton" onClick={this.onConfirmRemoveAccountModal} style={inputStyles.logoutYesButton} variant="text">{configJSON.Remove}</Button>
          </div>
        </div>
      </Fade>
    </Modal>
    )
  }

  renderBackButton = () => {
    const {checked} = this.props
    return (
      <Button
        className="backButton" variant="text"
      >
        <span
          data-test-id="backButtonArrow"
          onClick={this.onClickBackButton}
          className="icon"><img src={checked ? backArrowDark : backArrowWhite} /></span>
        <span data-test-id="pageTitle" >{configJSON.DiscoverabilityAndContactsHeading}</span>
      </Button>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const theme = this.props.checked ? lightTheme : darkTheme
    const style = audienceStyles(theme);
    const rightArrowUrl = this.getImageUrl(!this.props.checked, 'right');
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className="audienceContainer">
            {this.renderBackButton()}
            <Loader loading={this.state.loading} />
            <Box data-test-id="discoverablitySubTitle" className="discoverabilityAndContactsTitle">{configJSON.controlDiscoverabilytyHeading}</Box>
            <Box data-test-id="discoverBox"  className="audienceBox" style={style.audienceBox}>
              <Box style={style.audienceBoxTextContainer}>
                <div className="arrowFontFamily" style={{fontSize: "18px", fontWeight: 800}}>{configJSON.Discoverability}</div>
              </Box>
              <Box style={style.audienceRowOne}>
              <Box style={style.audienceBoxTextContainer}>
                <div className="arrowFontFamily" style={{fontSize: "16px", fontWeight: 600}}>{configJSON.LetsFindPeopleEmail}</div>
                <div className="arrowFontFamily" style={style.audienceTextTwo}>{configJSON.LetsFindPeopleEmailsConnectToArrows}</div>
              </Box>
              <CustomSwitch
                dataTestId={"email_switch"}
                name="emailSwitch"
                onChange={this.handleSwitch}
                checked={this.props.checked}
                valueSwitch={this.state.emailSwitch} />
              </Box>
              <Box style={style.audienceRowOne}>
              <Box style={style.audienceBoxTextContainer}>
                <div className="arrowFontFamily" style={{fontSize: "16px", fontWeight: 600}}>{configJSON.LetsFindPeoplePhone}</div>
                <div className="arrowFontFamily" style={style.audienceTextTwo}>{configJSON.LetsFindPeoplePhoneConnectToArrows}</div>
              </Box>
              <CustomSwitch
                dataTestId={"phone_switch"}
                name="phoneSwitch"
                onChange={this.handleSwitch}
                checked={this.props.checked}
                valueSwitch={this.state.phoneSwitch} />
              </Box>
            </Box>
            <Box data-test-id="discoverBox2"  className="audienceBox" style={style.audienceBox}>
            <Box style={style.audienceBoxTextContainer2}>
                <div className="arrowFontFamily" style={{fontSize: "18px", fontWeight: 800}}>{configJSON.Contacts}</div>
                <div className="arrowFontFamily" style={style.audienceTextTwo}>{configJSON.ContactsDiscriptionWeb}</div>
              </Box>
              <Box style={this.state.isAddressBookSync ? style.audienceRowOne :style.audienceDisabled }>
              <Box data-test-id="showRemoveModal" onClick={this.showRemoveModal} style={style.audienceBoxTextContainer}>
                <div className="arrowFontFamily" style={{fontSize: "16px", fontWeight: 600, color: '#EF4444'}}>{configJSON.RemoveAllContacts}</div>
                <div className="arrowFontFamily" style={style.audienceTextTwo}>{configJSON.RemoveAlContactsDescription}</div>
              </Box>
              <img style={webStyle.rowElTwo} onClick={this.showRemoveModal} src={rightArrowUrl} alt="right button" />
              </Box>
            </Box>
            {this.renderRemoveAllAccountsModal()}
          </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const subTitleBlock= {
  width: "385px",
  fontSize: "18px",
  marginLeft: "20px",
  fontWeight: 800
}


const boxContainer = {
  border: "1px solid #555555",
  backgroundColor: "#181818",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column" as "column",
  gap: "20px",
  padding: "10px"
}

const baseContainer = {
  height: "900px",
  backgroundColor: "inherit",
  color: "inherit",
  fontFamily: "Manrope"
}


const webStyle = {

rowElOne: {
  display: "flex",
  flexDirection: "row" as "row",
},
rowElTwo: {
  alignSelf: "flex-start",
  paddingTop: "5px"
},

};

const audienceStyles = (theme: Theme) =>({
  container: {
    color: theme.palette.text.primary,
    fontFamily: "Manrope",
    padding: "0px",
  },
  photoTagContainer: {
    height: "228px",
    width: "543px",
    padding: "20px 24px",
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as 'column',
    fontFamily: 'Manrope',
    fontSize: "16px",
    justifyContent: "center",
    gap: "15px",
    border: "1px solid #35383F", 
    borderRadius: "10px",
    color: theme.palette.text.primary,
  },
  removeAllAccountsModalContainer:{
    height: "320px",
    width: "540px",
    margin: "auto",
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as 'column',
    fontFamily: 'Manrope',
    fontSize: "16px",
    justifyContent: "center",
    gap: "15px",
    border: "1px solid #35383F", 
    borderRadius: "10px",
    color: theme.palette.text.primary,
  },
  photoTagBackdrop: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,

  },
  photoTagRow:{
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    fontSize: "16px",
    fontWeight: 600,
    gap: "12px",
    marginTop: "14px",
    paddingRight: "15px"
  },
  photoTagTxt:{
    fontSize: "18px",
    fontWeight: 800,
  },
  photoTagHead:{
    
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nextBtn:{
    width: "72px",
    height: "32px",
    borderRadius: "10px",
    opacity: "50%",
    backgroundColor: "#BA05F7",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    alignItems: "center",
  },
  audienceAndTaggingContainer:{
    width: "429px",
    ...baseContainer,
  },
  audienceSubtitle: {
    ...subTitleBlock,
    marginTop: "20px",
  },
  yourPostSubTitle:{
    ...subTitleBlock,
    marginTop: "55px",
    marginBottom: "10px",
  },
  contentYouSeeSubTitle: {
    ...subTitleBlock,
    marginTop: "60px",
    width: "335px",
  },
  audienceBox:{
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  audienceBoxTextContainer:{
    display: "flex",
    flexDirection: "column" as "column",
    width: "249px"
  },
  audienceBoxTextContainer2:{
    display: "flex",
    flexDirection: "column" as "column",
  },

  audienceDisabled:{
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: 'space-between',
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19.2px",
    opacity:0.4,
    cursor:'not-allowed'
  },
  audienceTextTwo:{
    width: "236px",
    color: '#6B6B6B',
    fontSize: "12px",
    fontFamily: "Manrope",
    marginTop: "5px",
    lineHeight: "14.4px",
    fontWeight: 500,
  },
  audienceRowOne:{
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: 'space-between',
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19.2px"
  },
  cbContainer:{
   height: "71px",
   display: "flex",
   flexDirection: "column" as "column",
  },
  rightArrow: {
    ...webStyle.rowElTwo,
    marginRight: "5px",
    width: "20px",
    height: "20px",
  },
  yourPostBoxContainer: {
    ...boxContainer,
    height: "134px",
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  exploreBox:{
    width: "385px",
    margin: "25px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  searchSettingsBox:{
    height: "87px",
    width: "385px",
    margin: "60px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  dmBox1:{
    height: "160px",
    width: "385px",
    margin: "30px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  dmBox2:{
    height: "115px",
    width: "385px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  dmBox3: {
    height: "143px",
    width: "385px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  dmBox4: {
    height: "19px",
    width: "385px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  checkboxes: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "10px"
  },
  cbRow: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    fontSize: "12px",
    paddingRight: "15px"
  },
  cb: {
    display: "block",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: "transparent",
    border: "1px solid #555555",
  },
  cbClicked: {
    width: "17px",
    height: "17px",
    borderRadius: "50%",
    background: "linear-gradient(to right, #3858E3 100%, #BA05F7 100%)",
    border: "none", 
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  checkBoxTick:{
    width: "10px",
    height: "9.5px",
  },
  muteAndBlockBox:{
    height: "110px",
    width: "385px",
    margin: "30px 0 auto 0",
    ...boxContainer,
    gap: "10px",
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  discoverablitySubTitle:{
    marginTop: "60px",
    width: "386px",
    fontSize: "12px",
    fontFamily: "Manrope",
    color: "#6B6B6B",
    fontWeight: 500
  },
  discoverBox:{
    height: "244px",
    width: "386px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },
  discoverBox2:{
    height: "310px",
    width: "386px",
    margin: "20px 0 auto 0",
    ...boxContainer,
    backgroundColor: theme.palette.type === "light" ? "#E9E9E9" : "#222222",
  },

})
// Customizable Area End
