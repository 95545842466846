// Customizable Area Start
import React from "react";
import LiveStreamingController, { Props } from "./LiveStreamingController.web";
import {
  Box,
  createStyles,
  withStyles,
  ThemeProvider,
} from "@material-ui/core";
import lightTheme from "../../../components/src/lightTheme";
import darkTheme from "../../../components/src/darkTheme";
import Livestream from "../../../components/src/Livestream";
import Loader from "../../../components/src/Loader";

export class LiveStreaming extends LiveStreamingController {
  constructor(props: Props) {
    super(props);
  }

  handleGoHome = () => {
    this.props.navigation.navigate("ActivityFeed");
    if (this.state.isHost && this.state.storyId) {
      this.updateEndLiveStory(this.state.storyId);
    }
  };

  render() {
    const { classes, checked } = this.props;
    const {
      isLoading,
      loggedUser,
      livestreamToken,
      channelName,
      uid,
      chatRoomId,
      chatToken,
      userChatName,
      isHost,
      ownerId,
    } = this.state;
    const theme = checked ? lightTheme : darkTheme;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.container}>
          <Loader loading={isLoading} />
          {channelName &&
            livestreamToken &&
            uid &&
            chatToken &&
            userChatName &&
            loggedUser && (
              <Livestream
                onGoHome={this.handleGoHome}
                channelName={channelName}
                livestreamToken={livestreamToken}
                uid={uid}
                roomId={chatRoomId}
                userId={userChatName}
                chatToken={chatToken}
                isHost={isHost}
                loggedUser={loggedUser}
                ownerId={ownerId}
              />
            )}
        </Box>
      </ThemeProvider>
    );
  }
}

const styles = createStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: "#070707",
  },
});

export default withStyles(styles)(LiveStreaming);

// Customizable Area End
