// Customizable Area Start
import React from "react";
import StartLivestreamController, { Props } from "./StartLivestreamController";
import {
  Box,
  Button,
  createStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  withStyles,
  ThemeProvider,
} from "@material-ui/core";
import { Live } from "./assets";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import lightTheme from "../../../components/src/lightTheme";
import darkTheme from "../../../components/src/darkTheme";
import Loader from "../../../components/src/Loader";

export class StartLivestream extends StartLivestreamController {
  constructor(props: Props) {
    super(props);
  }

  CustomMenuItem = withStyles(() => ({
    root: {
      backgroundColor: this.props.checked ? "#E9E9E9" : "#222",
      display: "flex",
      height: "44px",
      width: "100%",
      alignItems: "center",
      gap: "8px",
      justifyContent: "space-between",

      "&.MuiPaper-rounded": {
        borderRadius: "8px !important",
      },
      "&.MuiList-padding": {
        padding: "0px !important",
      },
      list: {
        paddingBottom: "0px",
        paddingTop: "0px",
      },
      "&.Mui-selected": {
        background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      },
      "&:hover": {
        backgroundColor: this.props.checked ? "#d3d3d3" : "black",
      },
      fontWeight: 600,
    },
  }))(MenuItem);

  render() {
    const { classes, checked } = this.props;
    const { isLoading } = this.state;
    const theme = checked ? lightTheme : darkTheme;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.container}>
          <Loader loading={isLoading} />
          <Box className={classes.wrapper}>
            <Typography className={classes.goLiveText}>Go Live</Typography>
            <img src={Live} />
            <Typography className={classes.title}>
              Add Live Video Details
            </Typography>
            <Typography className={classes.desc}>
              Go live by connecting to your choice of streaming software. To get
              started, add a title and select the audience for your live video.
            </Typography>
            <OutlinedInput
              name="title"
              placeholder="Add a title"
              className={classes.titleInput}
              value={this.state.livestreamTitle}
              onChange={this.handleChangeTitle}
            />
            <Select
              className={classes.selectAudience}
              IconComponent={(iconProps) => (
                <KeyboardArrowDownIcon
                  {...iconProps}
                  style={{
                    color: checked ? "black" : "white",
                  }}
                />
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "8px",
                    background: "none",
                  },
                },
                MenuListProps: {
                  style: {
                    marginTop: "8px",
                    borderRadius: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    overflow: "hidden",
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              name="audience"
              onChange={this.handleAudience}
              value={this.state.selectedAudience}
              variant="outlined"
            >
              {this.audience.map((val: any, index: number) => (
                <this.CustomMenuItem key={index} value={val}>
                  {val}
                </this.CustomMenuItem>
              ))}
            </Select>
            <Button
              className={classes.nextBtn}
              endIcon={<ChevronRightIcon />}
              onClick={this.createLivestreamStory}
            >
              Next
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

const styles = createStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: "#070707",
  },
  wrapper: {
    border: "1px solid #35383F",
    width: "620px",
    padding: "15px 24px 74px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  goLiveText: {
    fontFamily: "Manrope",
    color: "#FFF",
    fontWeight: 800,
    lineHeight: 1.8,
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "42px",
  },
  title: {
    marginTop: "16px",
    fontSize: "24px",
    fontWeight: 800,
    lineHeight: 1.13,
    fontFamily: "Manrope",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  desc: {
    marginTop: "15px",
    width: "460px",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    color: "#FFF",
    lineHeight: 1.2,
    textAlign: "center",
    marginBottom: "38px",
  },
  titleInput: {
    width: "380px",
    height: "56px",
    borderRadius: "10px",
  },
  selectAudience: {
    borderRadius: "10px",
    width: "380px",
    height: "56px",
    marginTop: "16px",
  },

  nextBtn: {
    padding: "15px 30px",
    borderRadius: "10px",
    display: "flex",
    gap: "5px",
    fontSize: "14px",
    fontFamily: "Manrope",
    color: "white",
    marginTop: "15px",
    fontWeight: 700,
    textTransform: "capitalize",
    background:
      "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))",
  },
});

export default withStyles(styles)(StartLivestream);

// Customizable Area End
