import React from "react";
// Customizable Area Start
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import {
  arrowLogoSvg,
  dashboardImage,
  defualtUserAvatar,
  drakThemeImage,
} from "./assets";
import {
  createTheme,
  styled,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Pagination from "@material-ui/lab/Pagination";

// Customizable Area End

// Customizable Area Start
const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: "#BA05F7",
    },
    info: {
      main: "#222222",
    },
    success: {
      main: "#000000",
    },
    warning: {
      main: "#222222",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FF00FF",
    },
    info: {
      main: "#FFFFFF",
    },
    success: {
      main: "#FFFFFF",
    },
    warning: {
      main: "#78716C",
    },
  },
});

const CustomePagination = withStyles({
  root: {
    "& .MuiPaginationItem-icon": {
      background:
        "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      color: "#ffffff !important",
      padding: "1px",
      borderRadius: "3px",
      fontSize: "30px",
    },
    "& .MuiPaginationItem-ellipsis": {
      color: "#A8A29E !important",
    },

    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#222222",
      color: "#FFFFFF !important",
    },
    "& .MuiPaginationItem-root": {
      color: "#6B6B6B !important",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 500,
    },
    marginTop: "6px",
  },
})(Pagination);

const CustomePaginationLightTheme = withStyles({
  root: {
    "& .MuiPaginationItem-icon": {
      background:
        "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      color: "#ffffff !important",
      padding: "1px",
      borderRadius: "3px",
      fontSize: "30px",
    },
    "& .MuiPaginationItem-ellipsis": {
      color: "#A8A29E !important",
    },

    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#E9E9E9",
      color: "#222222 !important",
    },
    "& .MuiPaginationItem-root": {
      color: "#6B6B6B !important",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 500,
    },
    marginTop: "6px",
  },
})(Pagination);

// Customizable Area End

import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const theme = this.props.checked ? lightTheme : darkTheme;
    const webStyles = {
      container: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
      },
      logoutButton: {
        textTransform: "capitalize" as "capitalize",
        fontFamily: "Manrope",
        position: "absolute" as "absolute",
        top: "15px",
        right: "144px",
        background: "none !important",
        fontSize: "14px",
        fontWeight: 700,
        padding: "0 px",
        cursor: "pointer",
      },
      darkColors: {
        color: "#FFFFFF",
      },
      switchLabel: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontFamily: "Manrope",
        fontSize: "clamp(12px,2vw,14px)",
      },
      arrowLogoStartText: {
        fontFamily: "Manrope",
        fontSize: "clamp(18px,2vw,24px)",
        fontWeight: 500,
        color: theme.palette.info.main,
      },
      adoptersText: {
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: theme.palette.success.main,
        marginTop: "60px",
        marginBottom: "20px",
      },
      startTableCell: {
        borderBottom: "none",
        borderTopLeftRadius: 10,
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: "#FFFFFF",
        width: "300px",
      },
      endTableCell: {
        flex: 1,
        borderBottom: "none",
        borderTopRightRadius: "4px",
        borderRight: "none",
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: "#FFFFFF",
      },
      startTableCellContent: {
        borderBottom: "none",
        borderTopLeftRadius: 10,
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: theme.palette.info.main,
        width: "300px",
      },
      tableCellContent: {
        borderBottom: "none",
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: theme.palette.info.main,
        width: "180px",
      },
      tableCellContentRow: {
        borderBottom: "none",
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: theme.palette.info.main,
        width: "180px",
      },
      tableCellContentUser: {
        borderBottom: "none",
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: theme.palette.info.main,
        width: "180px",
      },
      endTableCellContent: {
        borderBottom: "none",
        borderTopRightRadius: "4px",
        borderRight: "none",
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: theme.palette.info.main,
        marginLeft: "auto",
      },
      arrowLogoEndText: {
        textTransform: "capitalize" as "capitalize",
        color: "#8833FF",
        fontFamily: "Manrope",
        fontSize: "clamp(18px,2vw,24px)",
        fontWeight: 800,
        background:
          "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        fontStyle: "normal" as "normal",
        WebkitTextFillColor: "transparent",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
      },
      buttonText: {
        textTransform: "capitalize" as "capitalize",
      },
      tableHeadBox: {
        display: "flex",
        borderRadius: "20px",
        overflow: "hidden",
        marginBottom: "10px",
        height: "70px",
        justifyContent: "center",
        alignItems: "center",
        padding: "2px",
        background: "linear-gradient(to bottom, darkblue, darkorchid)",
      },
      tableCell: {
        borderBottom: "none",
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: "#FFFFFF",
        width: "180px",
      },
      tableHeadHeading: {
        display: "flex",
        width: "100%",
        borderRadius: "10px",
        overflow: "hidden",
        marginBottom: "5px",
        height: "50px",
        justifyContent: "center",
        alignItems: "center",
        background:
          "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      },
      tableHead: {
        borderRadius: "10px",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #8833FF",
        display: "flex",
        overflow: "hidden",
        marginBottom: "5px",
        height: "75px",
      },
      skipButton: {
        backgroundColor: "transparent",
        textTransform: "inherit" as "inherit",
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
        fontWeight: 600,
        color: "#8833FF",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      testButtonText: {
        textTransform: "capitalize" as "capitalize",
      },
      switchMainContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
        border: "2px solid red",
      },
      switchSubContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 5,
      },
      rightIcon: {
        fontSize: "22px",
        fontWeight: 700,
        marginTop: "3px",
      },
      switchContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
      },
      backButton: {
        textTransform: "capitalize" as "capitalize",
        background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        color: "#FFFFFF",
        fontWeight: 700,
        fontFamily: "Manrope",
        fontSize: "clamp(12px,2vw,14px)",
        "&:hover": {
          background:
            "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        },
        borderRadius: "10px",
        width: "10%",
        height: "40px",
      },
      switchGridItem: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      },
      themGrid: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
      },
      name: {
        color: theme.palette.info.main,
        fontWeight: 600,
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
      },
      nameRow: {
        color: theme.palette.info.main,
        fontWeight: 600,
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
      },
      user_name_styles: {
        color: theme.palette.info.main,
        fontWeight: 800,
        fontFamily: "Manrope",
        display: "flex",
        gap: 5,
        alignItems: "center",
        fontSize: "clamp(14px,2vw,18px)",
        marginLeft: "7px",
      },
      userName: {
        color: theme.palette.info.main,
        fontWeight: 600,
        fontFamily: "Manrope",
        fontSize: "clamp(14px,2vw,18px)",
      },
      description: {
        color: theme.palette.warning.main,
        fontWeight: 500,
        fontFamily: "Manrope",
        fontSize: "clamp(10px,2vw,12px)",
      },
      descriptionUser: {
        color: theme.palette.warning.main,
        fontWeight: 500,
        fontFamily: "Manrope",
        fontSize: "clamp(10px,2vw,12px)",
        marginLeft: "7px",
        width: "227px"
      },
      profileImage: {
        height: "56px",
        width: "56px",
        borderRadius: "50%",
        border: "2px solid #F0E5FF",
      },
      profileContainer: {
        display: "flex",
        flexDirection: "row" as "row",
        alignItems: "center",
        gap: 3,
      },
    };

    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Box
          style={
            this.props.checked
              ? staticStyles.backgroundImage
              : staticStyles.backgroundImageDark
          }
        >
          <Container style={webStyles.container}>
            <Grid container style={staticStyles.gridContainer}>
              <Button
                onClick={this.handleLogout}
                style={webStyles.logoutButton}
                data-test-id="logoutBtn"
                endIcon={<ChevronRightIcon style={webStyles.rightIcon} />}
              >
                {configJSON.logout}
              </Button>
              <Grid item>
                <Box style={staticStyles.logoContainer}>
                  <Loader loading={this.state.isLoading} />
                  <img src={arrowLogoSvg} />
                  <Typography
                    style={webStyles.arrowLogoStartText}
                    data-test-id="welcome"
                  >
                    {configJSON.arrowLogostartText}
                    <Box component="span" style={webStyles.arrowLogoEndText}>
                      {configJSON.arrowLogoEndText}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container style={staticStyles.tableGridContainer}>
              <Grid item xs={12} sm={12} md={11}>
                <Box style={staticStyles.adoptersContainer}>
                  <Typography style={webStyles.adoptersText}>
                    {configJSON.adoptersText} {this.state.totalRecords}
                  </Typography>
                </Box>
              </Grid>
              <GridTableContainerStyled item xs={12} md={11} checked={this.props.checked}>
                <Table>
                  <TableHead>
                    <TableRow style={webStyles.tableHeadHeading}>
                      <TableCell style={webStyles.startTableCell}>
                        {configJSON.user}
                      </TableCell>
                      <TableCell style={webStyles.tableCell}>
                        {configJSON.twitter}
                      </TableCell>
                      <TableCell style={webStyles.tableCell}>
                        {configJSON.instagram}
                      </TableCell>
                      <TableCell style={webStyles.tableCell}>
                        {configJSON.facebook}
                      </TableCell>
                      <TableCell style={webStyles.endTableCell} />
                    </TableRow>
                  </TableHead>

                  {this.state.currentPage === 1 && (
                    <TableHead>
                      <TableRow style={webStyles.tableHead}>
                        <TableCell style={webStyles.startTableCellContent}>
                          <Box style={webStyles.profileContainer}>
                            <img
                              src={
                                this.state.loggedUser.profilePhoto !== null
                                  ? this.state.loggedUser.profilePhoto
                                  : defualtUserAvatar
                              }
                              style={webStyles.profileImage}
                            />
                            <Box>
                              <Typography
                                data-test-id="loggedInuser"
                                style={webStyles.user_name_styles}
                              >
                                {this.formatUsernameText(this.state.loggedUser.username)}{" "}
                                {this.renderVerifyIcon({
                                  user_subscription:
                                    this.state.loggedUser.subscription,
                                })}{" "}
                                {this.renderOwnershipIcon({
                                  user_ownership:
                                    this.state.loggedUser.ownership,
                                })}
                              </Typography>
                              <Typography style={webStyles.descriptionUser}>
                                {this.formateDescription(this.state.loggedUser.description!==null?this.state.loggedUser.description:'')}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell style={webStyles.tableCellContentUser}>
                          <Typography style={webStyles.userName}>
                            {this.formatUsernameText(`@${this.state.loggedUser.twitterName!==null?this.state.loggedUser.twitterName:this.state.loggedUser.name }`)}
                          </Typography>
                          <Typography style={staticStyles.userFollowers}>
                            {this.state.loggedUser.twitterFollowers!==null ? this.state.loggedUser.twitterFollowers : this.state.loggedUser.followers}{" "}
                            {configJSON.followers}
                          </Typography>
                        </TableCell>
                        <TableCell style={webStyles.tableCellContentUser}>
                          <Typography style={webStyles.name}>
                          {this.formatUsernameText(`@${this.state.loggedUser.name}`)}
                          </Typography>
                          <Typography style={staticStyles.userFollowers}>
                            {this.state.loggedUser.followers}{" "}
                            {configJSON.followers}
                          </Typography>
                        </TableCell>
                        <TableCell style={webStyles.tableCellContentUser}>
                          <Typography style={webStyles.userName}>
                          {this.formatUsernameText(`@${this.state.loggedUser.name}`)}
                          </Typography>
                          <Typography style={staticStyles.userFollowers}>
                            {this.state.loggedUser.followers}{" "}
                            {configJSON.followers}
                          </Typography>
                        </TableCell>
                        <TableCell style={webStyles.endTableCellContent}>
                          <Button
                            data-test-id="edit"
                            style={staticStyles.editButton}
                            onClick={this.navigateToProfile}
                          >
                            {configJSON.edit}
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}

                  {this.state.followers.map((item) => (
                    <TableHead key={item.id}>
                      <TableRow style={webStyles.tableHead}>
                        <TableCell style={webStyles.startTableCellContent}>
                          <Box style={webStyles.profileContainer}>
                            <img
                              src={
                                item.profilePhoto !== null
                                  ? item.profilePhoto
                                  : defualtUserAvatar
                              }
                              style={webStyles.profileImage}
                            />
                            <Box>
                              <Typography
                                data-test-id = {`registeredUser${item.id}`}
                                style={webStyles.user_name_styles}
                              >
                                {this.formatUsernameText(item.username)}{" "}
                                {this.renderVerifyIcon({
                                  user_subscription:
                                    item.subscription,
                                })}{" "}
                                {this.renderOwnershipIcon({
                                  user_ownership:
                                    item.ownership,
                                })}
                              </Typography>
                              <Typography style={webStyles.descriptionUser}>
                                {this.formateDescription(item.description!==null?item.description:'')}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell style={webStyles.tableCellContent}>
                          <Typography style={webStyles.name}>
                            {this.formatUsernameText(`@${item.name}`)}
                          </Typography>
                          <Typography style={staticStyles.followers}>
                            {item.followers} followers
                          </Typography>
                        </TableCell>
                        <TableCell style={webStyles.tableCellContentRow}>
                          <Typography style={webStyles.nameRow}>
                          {this.formatUsernameText(`@${item.name}`)}
                          </Typography>
                          <Typography style={staticStyles.followers}>
                            {item.followers} followers
                          </Typography>
                        </TableCell>
                        <TableCell style={webStyles.tableCellContent}>
                          <Typography style={webStyles.name}>
                          {this.formatUsernameText(`@${item.name}`)}
                          </Typography>
                          <Typography style={staticStyles.followers}>
                            {item.followers} followers
                          </Typography>
                        </TableCell>
                        <TableCell style={webStyles.endTableCellContent}>
                          {!item.isfollowing ? (
                            <Button
                              data-test-id={`follow${item.id}`}
                              style={staticStyles.profileButton}
                              onClick={() => this.handleFollowUser(item.id)}
                            >
                              {configJSON.follow}
                            </Button>
                          ) : (
                            <Button
                              data-test-id={`unfollow${item.id}`}
                              style={staticStyles.followingBtn}
                              onClick={() => this.handleFollowingUser(item.id)}
                            >
                              {configJSON.following}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  ))}
                </Table>
              </GridTableContainerStyled>
              <Grid item xs={12} sm={12} md={11}>
                <Box style={staticStyles.paginationContainer}>
                  {this.props.checked ? (
                    <CustomePaginationLightTheme
                      data-test-id="pagination"
                      count={this.state.totalPages}
                      shape="rounded"
                      onChange={this.handlePageChange}
                    />
                  ) : (
                    <CustomePagination
                      data-test-id="pagination"
                      count={this.state.totalPages}
                      shape="rounded"
                      onChange={this.handlePageChange}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const staticStyles = {
  backgroundImage: {
    backgroundImage: `url(${dashboardImage})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    alignItem: "center"
  },
  backgroundImageDark: {
    backgroundImage: `url(${drakThemeImage})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    alignItem: "center"
  },
  gridContainer: {
    display: "flex",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    marginTop: "20px"
  },
  tableGridContainer: {
    justifyContent: "center",
  },
  adoptersContainer: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  followers: {
    color: "#6B6B6B",
    fontWeight: 500,
    fontFamily: "Manrope",
    fontSize: "clamp(12px,2vw,14px)",
  },
  userFollowers: {
    color: "#6B6B6B",
    fontWeight: 500,
    fontFamily: "Manrope",
    fontSize: "clamp(12px,2vw,14px)",
  },
  profileButton: {
    textTransform: "capitalize" as "capitalize",
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    color: "#FFFFFF",
    fontWeight: 600,
    fontFamily: "Manrope",
    fontSize: "clamp(12px,2vw,14px)",
    "&:hover": {
      background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    },
    borderRadius: "5px",
    height: "35px",
    width: "73px",
  },
  followingBtn: {
    textTransform: "capitalize" as "capitalize",
    fontWeight: 600,
    fontFamily: "Manrope",
    fontSize: "clamp(12px,2vw,14px)",
    borderRadius: "5px",
    height: "35px",
    width: "84px",
    border: "1px solid #8833FF",
  },
  editButton: {
    textTransform: "capitalize" as "capitalize",
    backgroundColor: "transparent",
    color: "#8833FF",
    fontWeight: 600,
    fontFamily: "Manrope",
    fontSize: "clamp(12px,2vw,14px)",
    "&:hover": {
      background: "transparent",
    },
    borderRadius: "5px",
    height: "35px",
    width: "73px",
    border: "1px solid #8833FF",
  },
};
export const GridTableContainerStyled = styled(Grid)(({checked}: {checked?: boolean}) => ({
  height: '460px',
  overflowY: "auto",
  paddingRight: '4px',
  '&::-webkit-scrollbar': {
    width: '6px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: checked ? "grey" : "#322B3D",
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#221A2D',
    borderRadius: '5px',
  },
}))
// Customizable Area End
