// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import { IUserProfile } from "../../customisableuserprofiles/src/ProfileController.web";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  checked: boolean;
}

interface S {
  isLoading: boolean;
  loggedUser: IUserProfile | null;
  channelName: string | null;
  livestreamToken: string | null;
  uid: number | null;
  chatRoomId: string | null;
  chatToken: string | null;
  userChatName: string | null;
  isHost: boolean;
  ownerId: number | null;
  storyId: string | null;
}

interface SS {
  id: any;
}

export default class LiveStreamingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getLoggedUserId: string = "";
  getLivestreamTokenId: string = "";
  getChatUserTokenId: string = "";
  updateEndLiveStoryId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isLoading: false,
      loggedUser: null,
      channelName: null,
      livestreamToken: null,
      uid: null,
      chatRoomId: null,
      chatToken: null,
      userChatName: null,
      isHost: false,
      ownerId: null,
      storyId: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const channelName = this.props.navigation.getParam("channelName");
    const chatRoomId = this.props.navigation.getParam("chatRoomId");
    const role = this.props.navigation.getParam("role");
    const ownerId = this.props.navigation.getParam("ownerId");
    const storyId = this.props.navigation.getParam("storyId");
    this.setState({
      channelName,
      chatRoomId,
      isHost: role === "host" ? true : false,
      ownerId: ownerId ? Number(ownerId) : null,
      storyId,
    });

    this.getLoggedUser();
    this.getChatUserToken();
    this.getLivestreamToken(channelName);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.apiSuccessCallBackController(apiRequestCallId, responseJson);
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getLoggedUserId]: this.handleGetLoggedUserResponse,
      [this.getChatUserTokenId]: this.handleGetChatUserTokenIdResponse,
      [this.getLivestreamTokenId]: this.handleLivestreamTokenResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJson: any) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  handleLivestreamTokenResponse = (responseJson: any) => {
    this.setState({ isLoading: false });
    if (responseJson?.data) {
      this.setState({
        livestreamToken: responseJson.data.rtc_token,
        uid: responseJson.data.uid,
      });
    } else {
      toast.error("Fail to get livestream token");
    }
  };

  handleGetChatUserTokenIdResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        chatToken: responseJson.data?.token,
        userChatName: responseJson.data?.username,
      });
    } else {
      toast.error("Fail to get chat token");
    }
  };

  handleGetLoggedUserResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        loggedUser: responseJson.data,
      });
    }
  };

  getLoggedUser = async () => {
    const token = await getStorageData("authToken");
    this.getLoggedUserId = sendAPIRequest(
      "account_block/accounts/logged_user",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  getChatUserToken = async () => {
    const token = await getStorageData("authToken");
    const url = `bx_block_agora/chat_user_tokens`;
    this.getChatUserTokenId = sendAPIRequest(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token,
      },
    });
  };

  getLivestreamToken = async (channelName: string) => {
    const token = await getStorageData("authToken");
    const url = `bx_block_agora/rtc_tokens?channel_name=${channelName}`;
    this.setState({ isLoading: true });
    this.getLivestreamTokenId = sendAPIRequest(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token,
      },
    });
  };

  updateEndLiveStory = async (storyId: string) => {
    const token = await getStorageData("authToken");
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append(
      "livestream_session_attributes[end_at]",
      new Date().toString()
    );

    const url = `bx_block_broadcast_message/stories/${storyId}`;
    this.updateEndLiveStoryId = sendAPIRequest(url, {
      method: "PATCH",
      headers: {
        token,
      },
      body: formData,
    });
  };
}

// Customizable Area End
