import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {CometChat} from "@cometchat/chat-sdk-javascript";
import { EMITTER_EVENT_VARIABLES, emitterEvents, generateTokenCall, startCallSession } from "../../../components/src/utils";
import { EventSubscription } from "fbemitter";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sessionCallID: string;
  callerInformation: {
    name: string;
    typeCaller: string;
    avatar?: string;
    userName?: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class IncomingCallController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subscriptionIncomingCall: EventSubscription | null = null;
  subscriptionCancelCall: EventSubscription | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sessionCallID: "",
      callerInformation: {
        name: "",
        avatar: "",
        typeCaller: "",
        userName: "",
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    switch (message.id) {
      case getName(MessageEnum.SessionResponseMessage): {
        break;
      }
      case getName(MessageEnum.NavigationPayLoadMessage): {
        break;
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.subscriptionIncomingCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.INCOMING_CALL, (call: CometChat.Call) => {
      const {username} = call.getSender().getMetadata() as unknown as {username: string};
      const receiverType = call.getReceiverType();
      const avatar = call.getSender().getAvatar();
      const sessionCallID = call.getSessionId();
      const name = call.getSender().getName();
      const callerInformation = {
        name,
        typeCaller: receiverType,
        avatar,
        userName: username
      }
      if(sessionCallID) {
        this.setState({sessionCallID, callerInformation})
      }

    })
    this.subscriptionCancelCall = emitterEvents.addListener(EMITTER_EVENT_VARIABLES.CANCEL_CALL, (call: CometChat.Call) => {
      this.handleResetCall();
    })
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    if (this.subscriptionIncomingCall) {
      this.subscriptionIncomingCall.remove()
    }
  }

  handleResetCall = () => {
    this.setState({sessionCallID: "", callerInformation: {
      name: "",
      avatar: "",
      userName: "",
      typeCaller: ""
    }})
    emitterEvents.emit(EMITTER_EVENT_VARIABLES.CLOSE_MODAL_CALL, true)
  }

  handleAcceptCall = async () => {
    try {
      const call = await CometChat.acceptCall(this.state.sessionCallID)
      console.log("Call accepted successfully:", call);
      this.handleResetCall();
      const token = await generateTokenCall(call.getSessionId())
      startCallSession(token, call.getSessionId());
    }
    catch (error) {
      console.log("Call acceptance failed with error", error);
    }
  }

  handleRejectCall = async () => {
    try {
      const status = CometChat.CALL_STATUS.REJECTED;
      const call = await CometChat.rejectCall(this.state.sessionCallID, status)
      console.log("Call rejected successfully:", call);
      this.handleResetCall();
    } catch(error) {
      console.error("Call Reject failed with error", error)
    } 
  }

  // Customizable Area End
}
